import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import arrowLeft from '../../assets/images/arrowLeft.svg';
import editIcon from '../../assets/images/editIcon.svg';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { mapModalAC } from '../../store/reducers/main';

const Address = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const addressName = useAppSelector(state => state.mainReducer.addressName);

  return (
    <div className='flex justify-center mb-14'>
      <div className='w-[1180px] px-5'>
        <div className='flex items-center'>
          <Link to='/restaurant' className='flex items-center'>
            <img src={arrowLeft} className='w-[35px]' alt='' onClick={() => navigate(-1)} />
          </Link>
          <span className='font-semibold ml-4 max-sm:text-xl text-2xl text-black'>Управление адресом</span>
        </div>
        <div className='mt-10 ml-12'>
          <div className='w-[340px] p-5 rounded-xl flex justify-between' style={{ backgroundColor: '#EFEFEF' }}>
            <p className='w-[80%]'>{addressName ? addressName : 'Адрес не выбран'}</p>
            <img src={editIcon} alt='' className='cursor-pointer' onClick={() => dispatch(mapModalAC(true))} />
          </div>
          <div className='mt-10 bg-yellow w-[162px] h-[48px] flex items-center rounded-xl text-center cursor-pointer'>
            <p className='text-center w-full' onClick={() => dispatch(mapModalAC(true))}>
              Добавить адрес
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Address;
