import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import arrowLeft from '../../assets/images/arrowLeft.svg';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getOrdersApi } from '../../http/main';
import getStatus from '../../utils/getStatus';
import { cancelOrderApi } from '../../http/restaurants';

const MyOrders = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [pickedFav, setPickedFav] = useState('current');
  const pastOrders = useAppSelector(state => state.mainReducer.pastOrders);
  const currentOrders = useAppSelector(state => state.mainReducer.currentOrders);
  const user = useAppSelector(state => state.appReducer.user);

  const cancelOrder = (item: any) => {
    const formData: any = new URLSearchParams();

    formData.append('code', 'RU');
    formData.append('order_id', item.order_id);

    dispatch(
      cancelOrderApi(formData, () => {
        dispatch(getOrdersApi('current', user.consumer_id));
        dispatch(getOrdersApi('past', user.consumer_id));
      })
    );
  };

  useEffect(() => {
    if (user.consumer_id) {
      dispatch(getOrdersApi('current', user.consumer_id));
      dispatch(getOrdersApi('past', user.consumer_id));
    }
  }, [user]);

  return (
    <div className='flex justify-center mb-14'>
      <div className='w-[1180px] px-5 min-h-[355px]'>
        <div className='flex items-center'>
          <Link to='/' className='flex items-center'>
            <img src={arrowLeft} className='w-[35px]' alt='' onClick={() => navigate(-1)} />
          </Link>
          <span className='font-semibold ml-4 max-sm:text-xl text-2xl text-black'>Мои заказы</span>
        </div>
        <div className='mt-10'>
          <div className='flex max-sm:justify-between'>
            <div
              onClick={() => setPickedFav('current')}
              className={`${pickedFav === 'current' ? 'bg-yellow' : 'bg-[#EFEFEF]'} mr-5 w-[162px] h-[48px] flex items-center rounded-xl text-center cursor-pointer`}
            >
              <p className='text-center w-full'>Текущие</p>
            </div>
            <div
              onClick={() => setPickedFav('past')}
              className={`${pickedFav === 'past' ? 'bg-yellow' : 'bg-[#EFEFEF]'} w-[162px] h-[48px] flex items-center rounded-xl text-center cursor-pointer`}
            >
              <p className='text-center w-full'>Прошлые</p>
            </div>
          </div>
          <div className='mt-10'>
            {pickedFav === 'current' ? (
              <div>
                {currentOrders.length > 0 ? (
                  <div>
                    {currentOrders.map((item: any) => (
                      <div className='mb-7 flex cursor-pointer' onClick={() => navigate(`/myOrders/${item.order_id}`)}>
                        <div>
                          <img className='sm:w-72 w-32 h-32 object-cover h-full rounded-xl' src={item.logo} alt='' />
                        </div>
                        <div className='ml-10 max-sm:text-sm'>
                          <p className='text-xl font-semibold'>{item.name}</p>
                          <p>
                            <span className='font-semibold'>Сумма:</span> {item.gross_amount} {item.currency}
                          </p>
                          <p>
                            <span className='font-semibold'>Сумма доставки:</span> {item.net_amount - item.gross_amount}{' '}
                            {item.currency}
                          </p>
                          <p>
                            <span className='font-semibold'>Общая сумма:</span> {item.net_amount} {item.currency}
                          </p>
                          <p>
                            <span className='font-semibold'>Дата заказа:</span> {item.created_at}
                          </p>
                          <div className='mt-3'>{getStatus(item.status)}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className='mt-14 text-center'>Заказов нет</div>
                )}
              </div>
            ) : null}

            {pickedFav === 'past' ? (
              <div>
                {pastOrders.length > 0 ? (
                  <div>
                    {pastOrders.map((item: any) => (
                      <div className='mb-7 flex cursor-pointer' onClick={() => navigate(`/myOrders/${item.order_id}`)}>
                        <div>
                          <img className='sm:w-72 w-32 h-32 object-cover h-full rounded-xl' src={item.logo} alt='' />
                        </div>
                        <div className='ml-10 max-sm:text-sm'>
                          <p className='text-xl font-semibold'>{item.name}</p>
                          <p>
                            <span className='font-semibold'>Сумма:</span> {item.gross_amount} {item.currency}
                          </p>
                          <p>
                            <span className='font-semibold'>Сумма доставки:</span> {item.net_amount - item.gross_amount}{' '}
                            {item.currency}
                          </p>
                          <p>
                            <span className='font-semibold'>Общая сумма:</span> {item.net_amount} {item.currency}
                          </p>
                          <p>
                            <span className='font-semibold'>Дата заказа:</span> {item.created_at}
                          </p>
                          <div className='mt-3'>{getStatus(item.status)}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className='mt-14 text-center'>Заказов нет</div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyOrders;
