import { Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { isBranchModalOpenAC } from '../../store/reducers/restaurants';

const BranchModal = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isBranchModalOpen = useAppSelector(state => state.restaurantsReducer.isBranchModalOpen);
  const restaurantBranches = useAppSelector(state => state.restaurantsReducer.restaurantBranches);

  return (
    <div>
      <Modal
        open={isBranchModalOpen}
        onOk={() => dispatch(isBranchModalOpenAC(false))}
        onCancel={() => dispatch(isBranchModalOpenAC(false))}
        footer={null}
      >
        <p className='text-center text-2xl mb-7'>Выберите с какого адреса вам доставить</p>
        {restaurantBranches.map((item: any) => (
          <div
            onClick={() => {
              navigate(`/restaurant/${item?.id}`);
              dispatch(isBranchModalOpenAC(false));
            }}
            className='mb-5 pb-3 cursor-pointer'
            style={{ borderBottom: '1px solid #D8D8D8' }}
          >
            <p className='text-lg font-semibold'>{item.RussianName}</p>
            <p className='text-slate-400'>{item.address}</p>
          </div>
        ))}
      </Modal>
    </div>
  );
};

export default BranchModal;
