import searchIcon from '../../assets/images/searchIcon.svg';
import deliveryIcon from '../../assets/images/deliveryIcon.svg';
import starIcon from '../../assets/images/starIcon.svg';
import { useAppSelector } from '../../hooks/redux';
import { useRef, useState } from 'react';
import { Affix } from 'antd';

const RestaurantInfo = () => {
  const restaurantinfo = useAppSelector(state => state.restaurantsReducer.restaurantinfo);
  const searchContainerRef = useRef<any>(null);

  const [searchedDishes, setSearchedDishes] = useState<any>([]);

  const scrollToSection = (id: any, scrollToPx?: any) => {
    const element = document.getElementById(id);
    if (element) {
      const offset = scrollToPx;
      const elementPosition = element.offsetTop;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  const searchDish = (query: any) => {
    let searchedDishes = [];

    for (let i = 0; i < restaurantinfo.itemCategoryDetails.length; i++) {
      for (let k = 0; k < restaurantinfo.itemCategoryDetails[i].itemList.length; k++) {
        if (restaurantinfo.itemCategoryDetails[i].itemList[k]?.title.toLowerCase().includes(query.toLowerCase())) {
          const foundDish = restaurantinfo.itemCategoryDetails[i].itemList[k];
          searchedDishes.push(foundDish); // Добавление найденного блюда в массив
        }
      }
    }

    setSearchedDishes(searchedDishes);
  };

  return (
    <div>
      <div>
        <img className='w-full rounded-3xl lg:h-[300px]' src={restaurantinfo.logo} alt='' />
      </div>
      <div className='mt-5 sm:flex justify-between gap-10'>
        <div
          className='w-full relative max-sm:w-[100%] max-md:w-[60%] sm:mr-3 h-[40px] flex items-center rounded-xl'
          style={{ backgroundColor: '#F5F5F5' }}
        >
          <img src={searchIcon} alt='' className='ml-4 w-5 h-5' />
          <input
            type='text'
            placeholder='Найти блюдо'
            className='ml-4 h-full w-2/3 px-5 outline-none'
            style={{ backgroundColor: '#F5F5F5' }}
            onChange={e => searchDish(e.target.value)}
            ref={searchContainerRef}
          />
          {searchContainerRef?.current?.value !== '' ? (
            <div
              className='absolute top-[55px] rounded-lg w-full max-h-[180px] overflow-y-auto'
              style={{ backgroundColor: '#F5F5F5' }}
            >
              {searchedDishes.map((item: any) => (
                <div
                  key={item.title}
                  onClick={() => {
                    scrollToSection(item.item_id, 0);
                    searchContainerRef.current.value = '';
                    setSearchedDishes([]);
                  }}
                  className='mb-1 cursor-pointer px-5 py-2 hover:bg-yellow-100'
                >
                  <span className='font-semibold'>{item.title}</span> - {item.description}
                </div>
              ))}
            </div>
          ) : null}
        </div>
        <div className='w-full flex justify-between gap-5 max-sm:mt-5 text-sm'>
          <div
            className='p-1 w-full flex text-white items-center justify-center rounded-3xl'
            style={{ backgroundColor: '#2D2D2D' }}
            onClick={() => scrollToSection('ITM1673952553JVZ62404')}
          >
            <img className='w-4 h-6' src={deliveryIcon} alt='' />
            <span className='ml-2'>10-30 мин</span>
          </div>
          <div
            className='p-1 w-full flex text-white items-center justify-center rounded-3xl'
            style={{ backgroundColor: '#2D2D2D' }}
          >
            <img className='w-4 h-6' src={starIcon} alt='' />
            <span className='ml-2'>4.3</span>
          </div>
        </div>
      </div>
      <div className='max-sm:hidden'>
        <Affix offsetTop={78}>
          <div className='overflow-x-auto mt-5 pb-5 sticky-top'>
            <div className='flex md:flex-nowrap gap-5 items-center'>
              {restaurantinfo?.itemCategoryDetails?.map((item: any) => (
                <div key={item.item_category_id}>
                  <div
                    onClick={() => scrollToSection(item.item_category_id, 0)}
                    className='px-4 py-2 rounded-3xl cursor-pointer'
                    style={{
                      backgroundColor: '#F6F6F6',
                      width: 'fit-content',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <div className='!text-base'>{item.categoryTitle}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Affix>
      </div>
      <div className='sm:hidden'>
        <Affix offsetTop={146}>
          <div className='overflow-x-auto mt-5 pb-5 sticky-top'>
            <div className='flex md:flex-nowrap gap-5 items-center'>
              {restaurantinfo?.itemCategoryDetails?.map((item: any) => (
                <div key={item.item_category_id}>
                  <div
                    onClick={() => scrollToSection(item.item_category_id, 0)}
                    className='px-4 py-2 rounded-3xl cursor-pointer'
                    style={{
                      backgroundColor: '#F6F6F6',
                      width: 'fit-content',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <div className='!text-base'>{item.categoryTitle}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Affix>
      </div>
    </div>
  );
};

export default RestaurantInfo;
