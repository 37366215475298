import { combineReducers, configureStore } from '@reduxjs/toolkit';
import appReducer from './reducers/app';
import restaurantsReducer from './reducers/restaurants';
import mainReducer from './reducers/main'

const rootReducer = combineReducers({
  appReducer,
  restaurantsReducer,
  mainReducer
});

export const setupStore = () =>  configureStore({
  reducer: rootReducer,
});


export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
export type AppState = AppStore['getState'];
