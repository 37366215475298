import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getPromotionsApi } from '../../http/main';

const Promotions = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const promotions = useAppSelector(state => state.mainReducer.promotions);

  useEffect(() => {
    dispatch(getPromotionsApi());
  }, []);

  return (
    <div className='flex justify-center px-5 max-md:hidden'>
      <div className='w-[1180px]'>
        <div className='flex justify-between'>
          <h1 className='sm:text-4xl text-2xl font-semibold'>Акции и скидки</h1>
        </div>
        <div className='mt-10 overflow-x-auto' style={{ maxHeight: '60vh', overflowY: 'auto' }}>
          <div className='flex justify-between gap-3'>
            {promotions.map((category: any) => (
              <div className='flex justify-center'>
                <div className='h-[135px]'>
                  <img className='w-full h-full rounded-xl' src={category?.photoUrl} alt='' />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Promotions;
