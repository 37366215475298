import React from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { useLocation } from 'react-router-dom';
import { addToCartApi, removeFromCartApi, updateCartApi } from '../http/restaurants';
import { isAuthModalOpenAC } from '../store/reducers/app';
import openNotification from '../utils/openNotification';
import { addToKfcOrderAC, removeFromKfcOrderAC } from '../store/reducers/restaurants';

const Dishes = ({ data }: any) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const user = useAppSelector(state => state.appReducer.user);
  const kfcMenuList = useAppSelector(state => state.restaurantsReducer.kfcMenuList);

  const orders = useAppSelector(state => state.restaurantsReducer.orders);

  const isSelectedDish = (dish: any): any => orders.some((item: any) => dish.item_id === item.item_id);

  const selectedDish = (dish: any): any => orders.find((item: any) => dish.item_id === item.item_id);

  const addToCart = (item: any) => {
    if (orders.length > 0) {
      if (orders[0].restaurant_id === location.pathname.slice(12)) {
        const formData: any = new URLSearchParams();

        formData.append('code', 'RU');
        formData.append('qty', '1');
        formData.append('item_id', item.item_id);
        formData.append('consumer_id', user.consumer_id);
        formData.append('restaurant_id', location.pathname.slice(12));
        formData.append('store_type', '');
        formData.append('item_options', '[]');
        formData.append('item_instruction', '');

        dispatch(addToCartApi(formData, user.consumer_id));
        addKfcOrder(item);
      } else {
        openNotification('error', 'Вы уже выбрали с другого ресторана');
      }
    } else {
      const formData: any = new URLSearchParams();

      formData.append('code', 'RU');
      formData.append('qty', '1');
      formData.append('item_id', item.item_id);
      formData.append('consumer_id', user.consumer_id);
      formData.append('restaurant_id', location.pathname.slice(12));
      formData.append('store_type', '');
      formData.append('item_options', '[]');
      formData.append('item_instruction', '');

      dispatch(addToCartApi(formData, user.consumer_id));
      addKfcOrder(item);
    }
  };

  const updateCart = (item: any) => {
    const formData: any = new URLSearchParams();
    const cart = selectedDish(item);

    if (cart.qty <= 1) {
      removeFromCart(cart.cart_id);
      removeFromKfcOrder(item);
    } else {
      formData.append('code', 'RU');
      formData.append('qty', cart.qty - 1);
      formData.append('item_id', cart.item_id);
      formData.append('consumer_id', user.consumer_id);
      formData.append('restaurant_id', location.pathname.slice(12));
      formData.append('store_type', '');
      formData.append('item_options', '[]');
      formData.append('item_instruction', '');

      dispatch(updateCartApi(formData, user.consumer_id, cart.cart_id));
      removeFromKfcOrder(item);
    }
  };

  const addKfcOrder = (item: any) => {
    for (let i = 0; i < kfcMenuList.length; i++) {
      if (item.title === kfcMenuList[i].name) {
        const dish = {
          cost: kfcMenuList[i].price,
          id: kfcMenuList[i].id,
          name: kfcMenuList[i].name,
          price: kfcMenuList[i].price,
          promos: [],
          quantity: 1,
        };
        dispatch(addToKfcOrderAC(dish));
      }
    }
  };

  const removeFromKfcOrder = (item: any) => {
    for (let i = 0; i < kfcMenuList.length; i++) {
      if (item.title === kfcMenuList[i].name) {
        dispatch(removeFromKfcOrderAC(kfcMenuList[i].id));
      }
    }
  };

  const removeFromCart = (cart_id: any) => {
    dispatch(removeFromCartApi(user.consumer_id, cart_id));
  };

  return (
    <div>
      {data.length > 0 ? (
        <div>
          {data?.map((category: any) => (
            <div id={category.item_category_id}>
              <p className='text-3xl my-7'>{category.categoryTitle}</p>
              <div className='grid sm:grid-cols-3 grid-cols-2 gap-x-5 gap-y-5'>
                {category?.itemList?.map((item: any) => (
                  <div
                    id={item.item_id}
                    style={{ backgroundColor: '#F8F8F8', borderRadius: '30px' }}
                    className='p-1 flex flex-col'
                  >
                    <img
                      src={item?.cover_photo}
                      alt=''
                      className='w-full max-sm:min-h-[165px] min-h-[230px]'
                      style={{ borderRadius: '30px' }}
                    />
                    <div className='p-3 h-full flex flex-col justify-between'>
                      <div>
                        <p className='text-lg font-semibold'>{item?.title}</p>
                        <p className='text-sm text-slate-400'>{item?.ingredients}</p>
                      </div>
                      <div>
                        <p className='my-3 text-2xl font-semibold'>{item.price} TJS</p>
                        {isSelectedDish(item) && selectedDish(item)?.qty > 0 ? (
                          <div
                            style={{ backgroundColor: '#2D2D2D' }}
                            className='h-[40px] w-full text-black rounded-3xl font-semibold py-2 mt-auto flex justify-between text-white px-5 text-xl'
                          >
                            <span
                              className='cursor-pointer'
                              onClick={() => {
                                updateCart(item);
                              }}
                            >
                              -
                            </span>
                            <span>{selectedDish(item).qty}</span>
                            <span
                              className='cursor-pointer'
                              onClick={() => {
                                addToCart(item);
                              }}
                            >
                              +
                            </span>
                          </div>
                        ) : (
                          <button
                            onClick={() => {
                              if (user?.consumer_id) {
                                addToCart(item);
                              } else {
                                dispatch(isAuthModalOpenAC(true));
                              }
                            }}
                            className='h-[40px] w-full bg-yellow text-black rounded-3xl font-semibold py-2 mt-auto'
                          >
                            Заказать
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default Dishes;
