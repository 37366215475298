import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import arrowLeft from '../../assets/images/arrowLeft.svg';
import successIcon from '../../assets/images/successIcon.svg';
import { useAppSelector } from '../../hooks/redux';

const Notifications = () => {
  const navigate = useNavigate();

  const notifications = [
    {
      title: 'Ваш заказ доставлен ORD170999755585QIL108763',
    },
    {
      title: 'Ваш заказ ORD170999755585QIL108763 забирает Rustamzoda Xisrav',
    },
    {
      title: 'Ваш заказ готов',
    },
    {
      title: 'Ваш заказ доставлен ORD170999755585QIL108763',
    },
    {
      title: 'Ваш заказ ORD170999755585QIL108763 забирает Rustamzoda Xisrav',
    },
    {
      title: 'Ваш заказ готов',
    },
  ];

  return (
    <div className='flex justify-center mb-14'>
      <div className='w-[1180px] px-5'>
        <div className='flex items-center'>
          <Link to='/restaurant' className='flex items-center'>
            <img src={arrowLeft} className='w-[35px]' alt='' onClick={() => navigate(-1)} />
          </Link>
          <span className='font-semibold ml-4 max-sm:text-xl text-2xl text-black'>Уведомления</span>
        </div>
        <div className='mt-10'>
          <div>
            {notifications.map((item: any) => (
              <div className='flex justify-between items-center py-3' style={{ borderBottom: '1px solid #eee' }}>
                <p>{item.title}</p>
                <img className='w-7 h-7' src={successIcon} alt='' />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
