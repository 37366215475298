import React from 'react';
import facebookIcon from '../../assets/images/facebookIcon.svg';
import instagramIcon from '../../assets/images/instagramIcon.svg';
import tiktokIcon from '../../assets/images/tiktokIcon.svg';
import youtubeIcon from '../../assets/images/youtubeIcon.svg';
import telegramIcon from '../../assets/images/telegramIcon.svg';

const Footer = () => {
  return (
    <div className='flex justify-center' style={{ backgroundColor: '#F9F9F9' }}>
      <div className='w-[1180px] max-lg:px-5'>
        <div className='md:grid grid-cols-3 sm:py-20 py-10 gap-20'>
          <div className='col-span-2'>
            <p className='text-xl md:text-3xl'>Узнавай первым о скидках и новых</p>
            <p className='text-xl md:text-3xl'>заведениях присойдиняйся!</p>
            <div className='mt-10 w-[340px] md:w-[540px] sm:grid grid-cols-5 max-sm:flex max-sm:justify-between'>
              <div
                className='w-[56px] h-[56px] md:w-[86px] md:h-[86px] flex items-center justify-center bg-white rounded-2xl'
                style={{ border: '1px solid #e7e7e7' }}
              >
                <img src={facebookIcon} alt='' />
              </div>
              <div
                className='w-[56px] h-[56px] md:w-[86px] md:h-[86px] flex items-center justify-center bg-white rounded-2xl'
                style={{ border: '1px solid #e7e7e7' }}
              >
                <img src={instagramIcon} alt='' />
              </div>
              <div
                className='w-[56px] h-[56px] md:w-[86px] md:h-[86px] flex items-center justify-center bg-white rounded-2xl'
                style={{ border: '1px solid #e7e7e7' }}
              >
                <img src={tiktokIcon} alt='' />
              </div>
              <div
                className='w-[56px] h-[56px] md:w-[86px] md:h-[86px] flex items-center justify-center bg-white rounded-2xl'
                style={{ border: '1px solid #e7e7e7' }}
              >
                <img src={youtubeIcon} alt='' />
              </div>
              <div
                className='w-[56px] h-[56px] md:w-[86px] md:h-[86px] flex items-center justify-center bg-white rounded-2xl'
                style={{ border: '1px solid #e7e7e7', backgroundColor: '#aaa' }}
              >
                <img src={telegramIcon} alt='' />
              </div>
            </div>
          </div>
          <div className='col-span-1 text-base sm:text-lg max-md:mt-7'>
            <p className='sm:text-2xl font-semibold mb-2'>Call center: 1400</p>
            <p className=''>Наш колл ценр</p>
            <p className=''>работает круглосуточно</p>
            <p className='mt-5'>Республика Таджикистан</p>
            <p className=''>город Душанбе, ул. Багаудинова 15</p>
            <p className=''>Zood.delivery@gmail.com</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
