import deliveryIcon from '../../assets/images/deliveryIcon.svg';
import starIcon from '../../assets/images/starIcon.svg';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getRestaurantByIdApi } from '../../http/restaurants';
import { isBranchModalOpenAC, restaurantBranchesAC } from '../../store/reducers/restaurants';

const AllRestaurants = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const restaurants = useAppSelector(state => state.restaurantsReducer.restaurants);

  const checkBranch = (item: any) => {
    dispatch(
      getRestaurantByIdApi(item?.restaurant?.restaurant_id, (data: any) => {
        if (data?.branches?.length > 1) {
          dispatch(isBranchModalOpenAC(true));
          dispatch(restaurantBranchesAC(data?.branches));
        } else {
          navigate(`/restaurant/${item?.restaurant?.restaurant_id}`);
        }
      })
    );
  };

  return (
    <div className='flex justify-center mb-14 px-5'>
      <div className='w-[1180px]'>
        <div className='flex justify-between'>
          <h1 className='text-4xl font-semibold'>Рестораны</h1>
        </div>
        <div className='mt-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10'>
          {restaurants.map((item: any) => (
            <div onClick={() => checkBranch(item)} className='cursor-pointer'>
              <div>
                <img src={item?.restaurant?.logo} alt='' className='h-[168px] w-full rounded-xl' />
              </div>
              <div className='mt-3'>
                <div className='flex justify-between'>
                  <p className='font-semibold text-lg text-black'>{item?.restaurant?.restaurantNameRU}</p>
                  <div className='flex'>
                    <img src={deliveryIcon} className='w-6 h-6' alt='' />
                    <p className='ml-3'>{item?.restaurant?.preparation_time} мин</p>
                  </div>
                </div>
                <div className='flex justify-between text-sm mt-1'>
                  <p className='text-sm'>{item?.category_name}</p>
                  <div className='flex items-center'>
                    <span>Рейтинг</span>
                    <img className='ml-2 w-3 h-3' src={starIcon} alt='' />
                    <p className='ml-2'>{item?.restaurant?.rating_list[0]?.avg_rating}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AllRestaurants;
