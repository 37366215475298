import { Route, Routes } from 'react-router-dom';
import Main from './pages/Main/Main';
import Restaurant from './pages/Restaurant/Restaurant';
import RestaurantByCategory from './pages/RestaurantByCategory/RestaurantByCategory';
import Header from './components/container/Header';
import Footer from './components/container/Footer';
import { Order } from './pages/Order/Order';
import { useAppDispatch, useAppSelector } from './hooks/redux';
import PromotionsList from './components/PromotionsList';
import AllRestaurants from './components/restaurants/AllRestaurants';
import LoaderApp from './components/global/LoaderApp';
import BranchModal from './components/restaurants/BranchModal';
import MapComponent from './components/Map';
import AuthModal from './components/auth/AuthModal';
import AcceptedOrder from './pages/Order/AcceptedOrder';
import Profile from './pages/Profile/Profile';
import Address from './pages/Address/Address';
import Notifications from './pages/Notifications/Notifications';
import Favorites from './pages/Favorites/Favorites';
import MyOrders from './pages/MyOrders/MyOrders';
import OrderInfo from './pages/MyOrders/OrderInfo';
import { addToCartApi, removeFromCartApi, updateCartApi } from './http/restaurants';
import busketIconPng from './assets/images/busketIcon.png';
import busketIcon from './assets/images/busketIcon.svg';
import { Modal } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { isAuthAC, isAuthModalOpenAC, userAC } from './store/reducers/app';
import notification from './utils/openNotification';
import deliveryBusket from './assets/images/deliveryBusket.svg';
import { useEffect, useState } from 'react';
import { addToKfcOrderAC, removeFromKfcOrderAC } from './store/reducers/restaurants';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const orders: any = useAppSelector(state => state.restaurantsReducer.orders);
  const user = useAppSelector(state => state.appReducer.user);
  const orderPrice = useAppSelector(state => state.restaurantsReducer.orderPrice);
  const isLoading = useAppSelector(state => state.appReducer.isLoading);
  const kfcMenuList = useAppSelector(state => state.restaurantsReducer.kfcMenuList);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const selectedDish = (dish: any): any => orders.find((item: any) => dish.item_id === item.item_id);

  useEffect(() => {
    const storedUserJSON = getCookie('user');
    if (storedUserJSON) {
      dispatch(userAC(JSON.parse(storedUserJSON)));
      dispatch(isAuthAC(true));
    }
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const removeFromCart = (cart_id: any) => {
    dispatch(removeFromCartApi(user.consumer_id, cart_id));
  };

  const addToCart = (item: any) => {
    const formData: any = new URLSearchParams();

    formData.append('code', 'RU');
    formData.append('qty', '1');
    formData.append('item_id', item.item_id);
    formData.append('consumer_id', user.consumer_id);
    formData.append('restaurant_id', location.pathname.slice(12));
    formData.append('store_type', '');
    formData.append('item_options', '[]');
    formData.append('item_instruction', '');

    dispatch(addToCartApi(formData, user.consumer_id));
    addKfcOrder(item);
  };

  const updateCart = (item: any) => {
    const formData: any = new URLSearchParams();
    const cart = selectedDish(item);

    if (cart.qty <= 1) {
      removeFromCart(cart.cart_id);
      removeFromKfcOrder(item);
    } else {
      formData.append('code', 'RU');
      formData.append('qty', cart.qty - 1);
      formData.append('item_id', cart.item_id);
      formData.append('consumer_id', user.consumer_id);
      formData.append('restaurant_id', location.pathname.slice(12));
      formData.append('store_type', '');
      formData.append('item_options', '[]');
      formData.append('item_instruction', '');

      dispatch(updateCartApi(formData, user.consumer_id, cart.cart_id));
      removeFromKfcOrder(item);
    }
  };

  const addKfcOrder = (item: any) => {
    for (let i = 0; i < kfcMenuList.length; i++) {
      if (item.item_name === kfcMenuList[i].name) {
        const dish = {
          cost: kfcMenuList[i].price,
          id: kfcMenuList[i].id,
          name: kfcMenuList[i].name,
          price: kfcMenuList[i].price,
          promos: [],
          quantity: 1,
        };
        dispatch(addToKfcOrderAC(dish));
      }
    }
  };

  const removeFromKfcOrder = (item: any) => {
    for (let i = 0; i < kfcMenuList.length; i++) {
      if (item.item_name === kfcMenuList[i].name) {
        dispatch(removeFromKfcOrderAC(kfcMenuList[i].id));
      }
    }
  };

  const getCookie = (name: any) => {
    const cookieString = document.cookie;
    const cookies = cookieString.split(';').map(cookie => cookie.trim());

    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split('=');
      if (cookieName === name) {
        return decodeURIComponent(cookieValue);
      }
    }

    return null;
  };

  return (
    <div>
      <LoaderApp spinning={isLoading > 0}>
        <div>
          <Header />
          <div className='relative top-[170px] lg:top-[140px]'>
            <Routes>
              <Route path='*' element={<Main />} />
              <Route path='/' element={<Main />} />
              <Route path='/restaurant/:id' element={<Restaurant />} />
              <Route path='/order' element={<Order />} />
              <Route path='/profile' element={<Profile />} />
              <Route path='/address' element={<Address />} />
              <Route path='/myOrders' element={<MyOrders />} />
              <Route path='/myOrders/:id' element={<OrderInfo />} />
              <Route path='/notifications' element={<Notifications />} />
              <Route path='/favorites' element={<Favorites />} />
              <Route path='/acceptedOrder' element={<AcceptedOrder />} />
              <Route path='/map' element={<MapComponent />} />
              <Route path='/promotions' element={<PromotionsList />} />
              <Route path='/allRestaurants' element={<AllRestaurants />} />
              <Route path='/restaurantByCategory/:id' element={<RestaurantByCategory />} />
            </Routes>
            <Footer />
          </div>
          <BranchModal />
          <AuthModal />
        </div>
      </LoaderApp>
      <div>
        {orders.length > 0 ? (
          <button
            className='bg-yellow rounded-full busketIcon flex justify-center items-center md:hidden'
            onClick={showModal}
          >
            <span className='busketIconCount'>{orders.length}</span>
            <img src={busketIconPng} alt='' className='w-7 h-7' />
          </button>
        ) : null}
        <Modal
          title={
            <div className='flex items-center'>
              <img src={busketIcon} alt='' />
              <p className='ml-5 font-semibold'>Корзина</p>
            </div>
          }
          footer={false}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className='w-full h-[570px] rounded-3xl'>
            <div className='flex items-center justify-between'>
              {/* <p className='text-slate-400 cursor-pointer' onClick={() => dispatch(clearOrderAC([]))}>
              Очистить
            </p> */}
            </div>
            <div className='h-[420px] mt-5 overflow-y-auto'>
              {orders.length > 0 ? (
                <div>
                  {orders.map((order: any) => (
                    <div className='flex justify-between items-center mb-5'>
                      <img src={order?.item_cover_photo} alt='' className='w-[80px] h-[80px] rounded-xl' />
                      <div className='w-[120px]'>
                        <p className='font-semibold'>{order?.item_name}</p>
                        <p className='mt-1'>{order?.price} TJS</p>
                      </div>
                      <div>
                        <div
                          style={{ backgroundColor: '#DCDCDC' }}
                          className='w-[120px] text-black rounded-3xl font-semibold py-1 mt-auto flex justify-between items-center text-black px-5 text-base'
                        >
                          <span
                            className='cursor-pointer'
                            onClick={() => {
                              updateCart(order);
                            }}
                          >
                            -
                          </span>
                          <span>{order?.qty}</span>
                          <span
                            className='cursor-pointer'
                            onClick={() => {
                              addToCart(order);
                            }}
                          >
                            +
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className='h-full flex items-center justify-center'>
                  <div>
                    <p className='text-slate-500'>Здесь появятся товары</p>
                    <p className='text-slate-500'>которые вы добавите</p>
                  </div>
                </div>
              )}
            </div>
            <div className='mt-3'>
              <div className='flex items-center'>
                <img src={deliveryBusket} alt='' />
                <p className='ml-3'>Доставка от 10 TJS</p>
              </div>
              <button
                className='h-full w-full bg-yellow text-black rounded-3xl font-semibold py-3 mt-5'
                onClick={() => {
                  if (user?.consumer_id) {
                    if (orders.length > 0) {
                      navigate('/order');
                      setIsModalOpen(false);
                    } else {
                      notification('warning', 'Корзина пуста!');
                    }
                  } else {
                    dispatch(isAuthModalOpenAC(true));
                  }
                }}
              >
                Оформить заказ {orderPrice} TJS
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default App;
