import logo from '../../assets/images/logo.svg';
import searchIcon from '../../assets/images/searchIcon.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { isAuthModalOpenAC } from '../../store/reducers/app';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { useRef, useState } from 'react';
import busketIcon2 from '../../assets/images/busketIcon2.svg';
import userIcon from '../../assets/images/userIcon.svg';
import userProfileIcon from '../../assets/images/userProfileIcon.svg';
import locationIcon from '../../assets/images/locationIcon.svg';
import paymentIcon from '../../assets/images/paymentIcon.svg';
import favoritesIcon from '../../assets/images/favoritesIcon.svg';
import alertIcon from '../../assets/images/alertIcon.svg';
import ordersIcon from '../../assets/images/ordersIcon.svg';
import langIcon from '../../assets/images/langIcon.svg';
import cursorIcon from '../../assets/images/cursorIcon.svg';
import { addToCartApi, removeFromCartApi, updateCartApi } from '../../http/restaurants';
import { Drawer, Modal } from 'antd';
import MapComponent from '../Map';
import { mapModalAC } from '../../store/reducers/main';
import { addToKfcOrderAC, removeFromKfcOrderAC } from '../../store/reducers/restaurants';

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAuth = useAppSelector(state => state.appReducer.isAuth);
  const [showBusket, setShowBusket] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [open, setOpen] = useState(false);

  const user = useAppSelector(state => state.appReducer.user);
  const isMapModalOpen = useAppSelector(state => state.mainReducer.isMapModalOpen);
  const restaurants = useAppSelector(state => state.restaurantsReducer.restaurants);
  const searchContainerRef = useRef<any>(null);
  const orderPrice = useAppSelector(state => state.restaurantsReducer.orderPrice);
  const orders = useAppSelector(state => state.restaurantsReducer.orders);
  const fare = useAppSelector(state => state.mainReducer.fare);
  const addressName = useAppSelector(state => state.mainReducer.addressName);
  const kfcMenuList = useAppSelector(state => state.restaurantsReducer.kfcMenuList);

  const [searchedRestaurants, setSearchedRestaurants] = useState<any>([]);

  const selectedDish = (dish: any): any => orders.find((item: any) => dish.item_id === item.item_id);

  const searchRestaurant = (query: any) => {
    const matchingRestaurants = restaurants.filter((restaurant: any) =>
      restaurant.restaurant.restaurantNameRU.toLowerCase().includes(query.toLowerCase())
    );

    setSearchedRestaurants(matchingRestaurants);
  };

  const handleOk = () => {
    dispatch(mapModalAC(false));
  };

  const handleCancel = () => {
    dispatch(mapModalAC(false));
  };

  const addToCart = (item: any) => {
    const formData: any = new URLSearchParams();

    formData.append('code', 'RU');
    formData.append('qty', '1');
    formData.append('item_id', item.item_id);
    formData.append('consumer_id', user.consumer_id);
    formData.append('restaurant_id', location.pathname.slice(12));
    formData.append('store_type', '');
    formData.append('item_options', '[]');
    formData.append('item_instruction', '');

    dispatch(addToCartApi(formData, user.consumer_id));
    addKfcOrder(item);
  };

  const updateCart = (item: any) => {
    const formData: any = new URLSearchParams();
    const cart = selectedDish(item);

    if (cart.qty <= 1) {
      removeFromCart(cart.cart_id);
      removeFromKfcOrder(item);
    } else {
      formData.append('code', 'RU');
      formData.append('qty', cart.qty - 1);
      formData.append('item_id', cart.item_id);
      formData.append('consumer_id', user.consumer_id);
      formData.append('restaurant_id', location.pathname.slice(12));
      formData.append('store_type', '');
      formData.append('item_options', '[]');
      formData.append('item_instruction', '');

      dispatch(updateCartApi(formData, user.consumer_id, cart.cart_id));
      removeFromKfcOrder(item);
    }
  };

  const addKfcOrder = (item: any) => {
    for (let i = 0; i < kfcMenuList.length; i++) {
      if (item.item_name === kfcMenuList[i].name) {
        const dish = {
          cost: kfcMenuList[i].price,
          id: kfcMenuList[i].id,
          name: kfcMenuList[i].name,
          price: kfcMenuList[i].price,
          promos: [],
          quantity: 1,
        };
        dispatch(addToKfcOrderAC(dish));
      }
    }
  };

  const removeFromKfcOrder = (item: any) => {
    for (let i = 0; i < kfcMenuList.length; i++) {
      if (item.item_name === kfcMenuList[i].name) {
        dispatch(removeFromKfcOrderAC(kfcMenuList[i].id));
      }
    }
  };

  const removeFromCart = (cart_id: any) => {
    dispatch(removeFromCartApi(user.consumer_id, cart_id));
  };

  return (
    <div className='fixed top-0 left-0 right-0 bg-white z-50'>
      <div className='flex justify-between px-5 pt-5 sm:hidden'>
        <Link to='/' className=''>
          <img src={logo} alt='' className='cursor-pointer min-w-[100px] h-[48px] md:w-[145px] md:h-[55px]' />
        </Link>
        {isAuth ? (
          <div className='flex'>
            <div
              className='cursor-pointer'
              onClick={() => {
                setOpen(true);
                setShowBusket(false);
              }}
            >
              <img className='w-12 h-12' src={userIcon} alt='' />
            </div>
          </div>
        ) : (
          <div
            className='bg-yellow w-[112px] h-[48px] flex items-center rounded-xl text-center cursor-pointer'
            style={{ backgroundColor: '#EFEFEF' }}
          >
            <p className='text-center w-full font-semibold' onClick={() => dispatch(isAuthModalOpenAC(true))}>
              Войти
            </p>
          </div>
        )}
      </div>
      <div className='mx-auto max-lg:px-5 lg:w-[1180px] h-[98px] flex items-center justify-between bg-white z-50'>
        <Link to='/' className='max-sm:hidden mr-5'>
          <img src={logo} alt='' className='cursor-pointer min-w-[100px] h-[48px] md:w-[145px] md:h-[55px]' />
        </Link>
        <div
          className='w-[40%] relative max-sm:w-[100%] max-md:w-[60%] sm:mr-3 h-[48px] flex items-center rounded-xl'
          style={{ backgroundColor: '#F5F5F5' }}
        >
          <img src={searchIcon} alt='' className='ml-4 w-5 h-5' />
          <input
            type='text'
            placeholder='Найти ресторан'
            className='ml-4 h-full w-2/3 px-5 outline-none'
            style={{ backgroundColor: '#F5F5F5' }}
            onChange={e => searchRestaurant(e.target.value)}
            ref={searchContainerRef}
          />
          <div className='flex justify-end h-full w-[112px] ml-auto'>
            <button className='h-full w-full bg-yellow text-black rounded-xl font-semibold'>Найти</button>
          </div>
          {searchContainerRef?.current?.value !== '' ? (
            <div
              className='absolute top-[55px] rounded-lg w-full max-h-[180px] overflow-y-auto'
              style={{ backgroundColor: '#F5F5F5' }}
            >
              {searchedRestaurants.map((item: any) => (
                <div
                  key={item.restaurant.restaurant_id}
                  onClick={() => {
                    navigate(`/restaurant/${item.restaurant.restaurant_id}`);
                    searchContainerRef.current.value = '';
                  }}
                  className='mb-1 cursor-pointer px-5 py-2 hover:bg-yellow-100'
                >
                  <span className='font-semibold'>{item.restaurant.restaurantNameRU}</span> - {item.category_name}
                </div>
              ))}
            </div>
          ) : null}
        </div>

        {addressName === '' ? (
          <div className='h-[48px] mr-5 max-sm:hidden'>
            <button
              onClick={() => {
                dispatch(mapModalAC(true));
              }}
              className='px-4 h-full flex items-center w-full bg-yellow text-black rounded-xl font-semibold'
            >
              <img className='w-5 h-5 mr-5' src={cursorIcon} alt='' />
              <span className=''>Укажите адрес доставки</span>
            </button>
          </div>
        ) : null}

        <div
          className='cursor-pointer'
          onClick={() => {
            dispatch(mapModalAC(true));
          }}
        >
          {addressName ? (
            <div className='w-48 text-center font-semibold max-sm:hidden max-md:text-sm'>{addressName}</div>
          ) : null}
        </div>

        {isAuth ? (
          <div className='flex relative'>
            <div className='max-lg:hidden'>
              <div className='h-[48px] w-[130px] mr-5'>
                <button
                  onClick={() => {
                    setShowProfile(false);
                    setShowBusket(!showBusket);
                  }}
                  className='px-4 h-full flex items-center w-full bg-yellow text-black rounded-xl font-semibold'
                >
                  <img className='w-5 h-5 mr-5' src={busketIcon2} alt='' />
                  <span className='text-sm'>{orderPrice} TJS</span>
                </button>
              </div>
              {showBusket ? (
                <div
                  className='absolute top-[70px] right-[70px] w-[390px] shadow rounded-lg overflow-y-auto'
                  style={{ backgroundColor: '#F5F5F5' }}
                >
                  <div className='w-full px-5 py-5'>
                    {orders.length > 0 ? <p className='text-slate-400'>Корзинаs</p> : null}
                    <div className=''>
                      {orders.length > 0 ? (
                        <div className='mt-5'>
                          <div className='h-[200px] overflow-y-auto'>
                            {orders.map((order: any) => (
                              <div className='flex justify-between items-center mb-5'>
                                <img src={order?.item_cover_photo} alt='' className='w-[60px] h-[60px] rounded-xl' />
                                <div className='w-[120px]'>
                                  <p className='font-semibold'>{order?.item_name}</p>
                                  <p className='mt-1'>{order?.price} TJS</p>
                                </div>
                                <div>
                                  <div
                                    style={{ backgroundColor: '#DCDCDC' }}
                                    className='w-[100px] text-black rounded-3xl font-semibold py-1 mt-auto flex justify-between items-center text-black px-5 text-base'
                                  >
                                    <span
                                      className='cursor-pointer'
                                      onClick={() => {
                                        updateCart(order);
                                      }}
                                    >
                                      -
                                    </span>
                                    <span>{order?.qty}</span>
                                    <span
                                      className='cursor-pointer'
                                      onClick={() => {
                                        addToCart(order);
                                      }}
                                    >
                                      +
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className='mt-5'>
                            <p className='font-semibold mb-3'>Итого:</p>
                            <div className='flex justify-between mb-2'>
                              <p>Стоимость заказа:</p>
                              <p>{orderPrice} TJS</p>
                            </div>
                            <div className='flex justify-between mb-2'>
                              <p>Доставка:</p>
                              <p>{fare} TJS</p>
                            </div>
                            <div className='flex justify-between'>
                              <p>Скидка:</p>
                              <p>{0} TJS</p>
                            </div>
                          </div>
                          <button
                            className='h-full w-full bg-yellow text-black rounded-3xl font-semibold py-3 mt-5'
                            onClick={() => {
                              navigate('/order');
                              setShowBusket(!showBusket);
                            }}
                          >
                            Оформить заказ {orderPrice + fare} TJS
                          </button>
                        </div>
                      ) : (
                        <div className='h-full flex items-center justify-center'>
                          <div>
                            <p className='text-slate-500'>Здесь появятся товары</p>
                            <p className='text-slate-500'>которые вы добавите</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div>
              <div
                className='max-sm:hidden cursor-pointer'
                onClick={() => {
                  setShowProfile(!showProfile);
                  setShowBusket(false);
                }}
              >
                <img className='min-w-12 min-h-12' src={userIcon} alt='' />
              </div>
              {showProfile ? (
                <div
                  className='absolute top-[70px] right-[-1px] w-[330px] shadow rounded-lg overflow-y-auto'
                  style={{ backgroundColor: '#fff' }}
                >
                  <div className='w-full px-5 py-5'>
                    <p className=''>Профиль</p>
                    <div className='px-5'>
                      <div
                        className='flex items-center cursor-pointer mt-8'
                        onClick={() => {
                          setShowProfile(!showProfile);
                          navigate('profile');
                        }}
                      >
                        <img src={userProfileIcon} alt='' />
                        <div className='ml-10'>
                          <p className='text-center'>Настройки</p>
                          <p>Профиля</p>
                        </div>
                      </div>
                      <div className='mt-10'>
                        <p
                          className='flex items-center cursor-pointer mb-5'
                          onClick={() => {
                            setShowProfile(!showProfile);
                            navigate('address');
                          }}
                        >
                          <img className='mr-5 w-6 h-6' src={locationIcon} alt='' />
                          <span>Управление адресом</span>
                        </p>
                        <p className='flex items-center cursor-pointer mb-5'>
                          <img className='mr-5 w-6 h-6' src={paymentIcon} alt='' />
                          <span>Оплата</span>
                        </p>
                        <p
                          className='flex items-center cursor-pointer mb-5'
                          onClick={() => {
                            setShowProfile(!showProfile);
                            navigate('notifications');
                          }}
                        >
                          <img className='mr-5 w-6 h-6' src={alertIcon} alt='' />
                          <span>Уведомления</span>
                        </p>
                        <p
                          className='flex items-center cursor-pointer mb-5'
                          onClick={() => {
                            setShowProfile(!showProfile);
                            navigate('favorites');
                          }}
                        >
                          <img className='mr-5 w-6 h-6' src={favoritesIcon} alt='' />
                          <span>Избранные</span>
                        </p>
                        <p
                          className='flex items-center cursor-pointer mb-5'
                          onClick={() => {
                            setShowProfile(!showProfile);
                            navigate('myOrders');
                          }}
                        >
                          <img className='mr-5 w-6 h-6' src={ordersIcon} alt='' />
                          <span>Мои заказы</span>
                        </p>
                        <p className='flex items-center cursor-pointer mb-5'>
                          <img className='mr-5 w-6 h-6' src={langIcon} alt='' />
                          <span>Язык</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <div
            className='max-sm:hidden bg-yellow w-[112px] h-[48px] flex items-center rounded-xl text-center cursor-pointer'
            style={{ backgroundColor: '#EFEFEF' }}
          >
            <p className='text-center w-full font-semibold' onClick={() => dispatch(isAuthModalOpenAC(true))}>
              Войти
            </p>
          </div>
        )}
      </div>
      <Drawer onClose={() => setOpen(false)} open={open}>
        <div
          className='top-[85px] right-[50px] w-[330px] rounded-lg w-full overflow-y-auto'
          style={{ backgroundColor: '#fff' }}
        >
          <div className='w-full'>
            <p className=''>Профиль</p>
            <div className='px-5'>
              <div
                className='flex items-center cursor-pointer mt-8'
                onClick={() => {
                  setOpen(false);
                  navigate('profile');
                }}
              >
                <img className='w-24 h-24' src={userProfileIcon} alt='' />
                <div className='ml-10'>
                  <p className='text-center'>Настройки</p>
                  <p>Профиля</p>
                </div>
              </div>
              <div className='mt-10 text-base'>
                <p
                  className='flex items-center cursor-pointer mb-5 text-base'
                  onClick={() => {
                    setOpen(false);
                    navigate('address');
                  }}
                >
                  <img className='mr-5 w-6 h-6' src={locationIcon} alt='' />
                  <span>Управление адресом</span>
                </p>
                <p className='flex items-center cursor-pointer mb-5'>
                  <img className='mr-5 w-6 h-6' src={paymentIcon} alt='' />
                  <span>Оплата</span>
                </p>
                <p
                  className='flex items-center cursor-pointer mb-5'
                  onClick={() => {
                    setOpen(false);
                    navigate('notifications');
                  }}
                >
                  <img className='mr-5 w-6 h-6' src={alertIcon} alt='' />
                  <span>Уведомления</span>
                </p>
                <p
                  className='flex items-center cursor-pointer mb-5'
                  onClick={() => {
                    setOpen(false);
                    navigate('favorites');
                  }}
                >
                  <img className='mr-5 w-6 h-6' src={favoritesIcon} alt='' />
                  <span>Избранные</span>
                </p>
                <p
                  className='flex items-center cursor-pointer mb-5'
                  onClick={() => {
                    setOpen(false);
                    navigate('myOrders');
                  }}
                >
                  <img className='mr-5 w-6 h-6' src={ordersIcon} alt='' />
                  <span>Мои заказы</span>
                </p>
                <p className='flex items-center cursor-pointer mb-5'>
                  <img className='mr-5 w-6 h-6' src={langIcon} alt='' />
                  <span>Язык</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      <Modal width={1000} footer={false} open={isMapModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p className='mb-5 text-lg font-semibold'>Укажите адрес доставки:</p>
        <MapComponent type='header' />
      </Modal>
    </div>
  );
};

export default Header;
