import { Button, Form } from 'antd';
import { useState } from 'react';
import { useAppDispatch } from '../../hooks/redux';
import { sendOtpApi, signUpApi } from '../../http/auth';

const Registration = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [isFormState, setIsFormState] = useState(false);

  const [otpQuery, setOtpQuery] = useState({
    type: 'consumer',
    request_type: 'signUp',
    mobile_country_code: '+992',
    mobile: '',
  });

  const [query, setQuery] = useState({
    login_address: 'Душанбе',
    mobile: '',
    login_longitude: '0.0',
    device_token: 'e5b30c6cf9b3b2f23ceb2c32a6feafa21792674b98c5861194980d90526db999',
    device_id: 'A2D48C37-B906-4003-AFF7-836E2BD4E910',
    login_latitude: '0.0',
    register_type: 'email',
    otp: '',
    mobile_country_code: '+992',
    email: '',
    device_type: 'ios',
    user_type: 'consumer',
    code: 'RU',
    password: '',
    device_name: '',
    name: '',
  });

  const signUp = () => {
    dispatch(signUpApi(query));
  };

  const sendOtp = () => {
    dispatch(
      sendOtpApi(otpQuery, () => {
        setIsFormState(true);
      })
    );
  };

  return (
    <div className='mt-5'>
      <h1 className='text-2xl text-center font-semibold mb-5'>Регистрация</h1>
      {isFormState ? (
        <Form name='login' onFinish={signUp}>
          <Form.Item className='m-0' name='name' rules={[{ required: true, message: 'Обязательное поле!' }]}>
            <div className='w-full h-[40px] flex items-center rounded-xl' style={{ backgroundColor: '#F5F5F5' }}>
              <input
                onChange={e => {
                  setQuery({ ...query, name: e.target.value });
                }}
                type='text'
                placeholder='Введите имя'
                className='mx-5 h-full w-full px-5 outline-none text-base'
                style={{ backgroundColor: '#F5F5F5' }}
              />
            </div>
          </Form.Item>
          <div className='mt-5'>
            <Form.Item className='m-0' name='email' rules={[{ required: true, message: 'Обязательное поле!' }]}>
              <div className='w-full h-[40px] flex items-center rounded-xl' style={{ backgroundColor: '#F5F5F5' }}>
                <input
                  onChange={e => {
                    setQuery({ ...query, email: e.target.value });
                  }}
                  type='email'
                  placeholder='Введите почту'
                  className='mx-5 h-full w-full px-5 outline-none text-base'
                  style={{ backgroundColor: '#F5F5F5' }}
                />
              </div>
            </Form.Item>
          </div>
          <div className='mt-5'>
            <Form.Item
              className='m-0'
              name='otp'
              rules={[
                { required: true, message: 'Обязательное поле!' },
                { min: 5, message: 'Минимальное количество символов: 5' },
              ]}
            >
              <div className='w-full h-[40px] flex items-center rounded-xl' style={{ backgroundColor: '#F5F5F5' }}>
                <input
                  maxLength={5}
                  onChange={e => {
                    setQuery({ ...query, otp: e.target.value });
                  }}
                  type='text'
                  placeholder='Введите код подтверждения'
                  className='mx-5 h-full w-full px-5 outline-none text-base'
                  style={{ backgroundColor: '#F5F5F5' }}
                />
              </div>
            </Form.Item>
          </div>
          <div className='flex justify-end items-center'>
            <Form.Item>
              <div className='flex justify-end mt-5'>
                <Button
                  className='bg-yellow h-[40px] text-black font-semibold text-base'
                  type='primary'
                  htmlType='submit'
                >
                  Зарегистрироваться
                </Button>
              </div>
            </Form.Item>
          </div>
        </Form>
      ) : (
        <Form name='login' onFinish={sendOtp}>
          <Form.Item
            className='m-0'
            name='mobile'
            rules={[
              { required: true, message: 'Обязательное поле!' },
              { min: 9, message: 'Минимальное количество символов: 9' },
            ]}
          >
            <div className='w-full h-[40px] flex items-center rounded-xl' style={{ backgroundColor: '#F5F5F5' }}>
              <input
                maxLength={9}
                onChange={e => {
                  setOtpQuery({ ...otpQuery, mobile: e.target.value });
                  setQuery({ ...query, mobile: e.target.value });
                }}
                type='text'
                placeholder='Введите номер телефона'
                className='mx-5 h-full w-full px-5 outline-none text-base'
                style={{ backgroundColor: '#F5F5F5' }}
              />
            </div>
          </Form.Item>
          <div className='flex justify-end items-center'>
            <Form.Item>
              <div className='flex justify-end mt-5'>
                <Button
                  className='bg-yellow h-[40px] text-black font-semibold text-base'
                  type='primary'
                  htmlType='submit'
                >
                  Получить код
                </Button>
              </div>
            </Form.Item>
          </div>
        </Form>
      )}
    </div>
  );
};

export default Registration;
