import { Button, Form } from 'antd';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { verifyOtpApi } from '../../http/auth';

const Otp = () => {
  const dispatch = useAppDispatch();
  const mobileNumber = useAppSelector(state => state.appReducer.mobileNumber);
  const userId = useAppSelector(state => state.appReducer.userId);

  const [verifyOtpQuery, setVerifyOtpQuery] = useState<any>({
    user_id: userId,
    otp: 0,
    device_id: '123',
    device_token: '123',
    request_type: 'edit',
    device_name: '123',
    device_type: '123',
    mobile: mobileNumber,
    mobile_country_code: +992,
    code: 'RU',
    type: 'consumer',
  });

  const verifyOtp = () => {
    dispatch(verifyOtpApi(verifyOtpQuery));
  };

  return (
    <div className='mt-5'>
      <h1 className='text-xl text-center font-semibold mb-7'>Вход</h1>
      <Form name='login' onFinish={verifyOtp}>
        <Form.Item
          className='m-0'
          name='account_name'
          rules={[
            { required: true, message: 'Обязательное поле!' },
            { min: 5, message: 'Минимальное количество символов: 5' },
          ]}
        >
          <div className='w-full h-[40px] flex items-center rounded-xl' style={{ backgroundColor: '#F5F5F5' }}>
            <input
              maxLength={5}
              onChange={e => {
                setVerifyOtpQuery({ ...verifyOtpQuery, otp: e.target.value });
              }}
              type='text'
              placeholder='Введите код'
              className='ml-4 h-full w-2/3 px-5 outline-none text-base'
              style={{ backgroundColor: '#F5F5F5' }}
            />
          </div>
        </Form.Item>
        <div className='flex justify-end'>
          <Form.Item>
            <div className='flex justify-end mt-5'>
              <Button
                className='bg-yellow h-[40px] text-black font-semibold text-base'
                type='primary'
                htmlType='submit'
              >
                Подтвердить
              </Button>
            </div>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default Otp;
