import React, { useRef, useState } from 'react';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { coordinatesAC } from '../store/reducers/restaurants';
import { addressNameAC, mapModalAC } from '../store/reducers/main';
import { deliveryChargeApi } from '../http/restaurants';
import searchIcon from '../assets/images/searchIcon.svg';

const MapComponent = ({ type }: any) => {
  const dispatch = useAppDispatch();
  const [coordinates, setCoordinates] = useState(null);
  const restaurantId = useAppSelector(state => state.restaurantsReducer.restaurantId);
  const searchContainerRef = useRef<any>(null);
  const [searchedAddresses, setSearchedAddresses] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [showSearchedAddresses, setShowSearchedAddresses] = useState(false);

  const handleMapClick = async (e: any) => {
    const coords = e.get('coords');
    dispatch(coordinatesAC(coords));
    setCoordinates(coords);
    dispatch(mapModalAC(false));
    deliveryCharge(coords);

    try {
      // Выполнение запроса к сервису обратного геокодирования Яндекса
      const response = await fetch(
        `https://geocode-maps.yandex.ru/1.x/?apikey=8ae8c1aa-0a73-432a-9449-b0f70ea8cfbc&format=json&geocode=${coords[1]},${coords[0]}&kind=house`
      );
      const data = await response.json();
      const address =
        data.response.GeoObjectCollection.featureMember[0].GeoObject.metaDataProperty.GeocoderMetaData.text;
      dispatch(addressNameAC(address));
    } catch (error) {
      console.error('Ошибка при выполнении запроса обратного геокодирования:', error);
    }
  };

  const deliveryCharge = (coordinates: any) => {
    if (type !== 'header') {
      const formData: any = new URLSearchParams();

      formData.append('deliver_to_latitude', coordinates[0]);
      formData.append('deliver_to_longitude', coordinates[1]);
      formData.append('restaurant_id', restaurantId);
      formData.append('store_type', '');

      dispatch(deliveryChargeApi(formData));
    }
  };

  const handleSearchSubmit = async (e: any) => {
    e.preventDefault();
    try {
      // Выполнение запроса к сервису геокодирования Яндекса
      const response = await fetch(
        `https://geocode-maps.yandex.ru/1.x/?apikey=8ae8c1aa-0a73-432a-9449-b0f70ea8cfbc&format=json&geocode=${encodeURIComponent(searchQuery)}`
      );
      const data = await response.json();
      const results = data.response.GeoObjectCollection.featureMember.map((feature: any) => ({
        address: feature.GeoObject.metaDataProperty.GeocoderMetaData.text,
        coordinates: feature.GeoObject.Point.pos.split(' ').reverse().map(parseFloat),
      }));
      setSearchedAddresses(results);
      setShowSearchedAddresses(true);
    } catch (error) {
      console.error('Ошибка при выполнении запроса геокодирования:', error);
    }
  };

  return (
    <div className='flex justify-center mb-14'>
      <div className='w-[1180px]'>
        <div
          className='w-full mb-5 relative max-sm:w-[100%] max-md:w-[60%] sm:mr-3 h-[48px] flex items-center rounded-xl'
          style={{ backgroundColor: '#F5F5F5' }}
        >
          <img src={searchIcon} alt='' className='ml-4 w-5 h-5' />
          <input
            type='text'
            placeholder='Найти адрес'
            className='ml-4 h-full w-2/3 px-5 outline-none'
            style={{ backgroundColor: '#F5F5F5' }}
            onChange={e => setSearchQuery(e.target.value)}
            ref={searchContainerRef}
          />
          <div className='flex justify-end h-full w-[112px] ml-auto' onClick={handleSearchSubmit}>
            <button className='h-full w-full bg-yellow text-black rounded-xl font-semibold'>Найти</button>
          </div>
          {searchContainerRef?.current?.value !== '' ? (
            <div
              className='absolute top-[55px] rounded-lg w-full max-h-[180px] overflow-y-auto z-[9999]'
              style={{ backgroundColor: '#F5F5F5' }}
            >
              {showSearchedAddresses ? (
                <div>
                  {searchedAddresses.map((item: any) => (
                    <div
                      onClick={() => {
                        dispatch(coordinatesAC(item.coordinates));
                        setCoordinates(item.coordinates);
                        setShowSearchedAddresses(false);
                        deliveryCharge(item.coordinates);
                        dispatch(addressNameAC(item.address));
                        dispatch(mapModalAC(false));
                      }}
                      className='mb-1 cursor-pointer px-5 py-2 hover:bg-yellow-100'
                    >
                      <span>{item.address}</span>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
        <YMaps width='100%' query={{ apikey: 'dce42361-1559-49ca-bd2a-18b620f00694' }}>
          <div style={{ width: '100%', height: '100%' }}>
            <Map
              width={'100%'}
              height={400}
              defaultState={{ center: [38.5658, 68.7771], zoom: 12 }}
              onClick={handleMapClick}
            >
              {coordinates && <Placemark geometry={coordinates} options={{ iconColor: '#ffc700' }} />}
            </Map>
          </div>
        </YMaps>
      </div>
    </div>
  );
};

export default MapComponent;
