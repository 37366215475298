import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { getPromotionsApi } from '../http/main';

const PromotionsList = () => {
  const dispatch = useAppDispatch();
  const promotions = useAppSelector(state => state.mainReducer.promotions);

  useEffect(() => {
    dispatch(getPromotionsApi());
  }, []);

  return (
    <div className='flex justify-center mb-14'>
      <div className='w-[1180px]'>
        <div className='flex justify-between'>
          <h1 className='text-4xl font-semibold'>Акции и скидки</h1>
        </div>
        <div className='mt-10 grid grid-cols-4 gap-10'>
          {promotions.map((promotion: any) => (
            <div className='col-span-1'>
              <img src={promotion.photoUrl} alt='' className='w-full h-[134px] rounded-xl' />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PromotionsList;
