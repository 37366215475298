import { FC, ReactNode } from 'react';

import logo from '../../assets/images/logo.svg';

interface ISpinAppProps {
  children: ReactNode;
  spinning: boolean;
}

const SpinApp: FC<ISpinAppProps> = ({ children, spinning }) => (
  <>
    {spinning && (
      <div
        style={{
          position: 'fixed',
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
          zIndex: 9999999,
          background: 'rgba(255,255,255,0.75)',
        }}
        className='h-screen flex items-center justify-center index'
      >
        <div
          className='animate-bounce'
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img className='h-12' src={logo} alt='' />
        </div>
      </div>
    )}
    <div>{children}</div>
  </>
);

export default SpinApp;
