import React, { useEffect } from 'react';
import Promotions from '../../components/main/Promotions';
import Categories from '../../components/main/Categories';
import Restaurants from '../../components/main/Restaurants';
import Banner from '../../components/main/Banner';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getRestaurantListApi } from '../../http/restaurants';

const Main = () => {
  const dispatch = useAppDispatch();
  const restaurants: any = useAppSelector(state => state.restaurantsReducer.restaurants);

  useEffect(() => {
    dispatch(getRestaurantListApi());
  }, []);

  return (
    <div>
      <Promotions />
      <Categories />
      <Restaurants restaurants={restaurants} />
      <Banner />
    </div>
  );
};

export default Main;
