import { $host2 }from './index';
import { AppDispatch } from '../store';
import { isAuthAC, isAuthModalOpenAC, isLoadingAC, otpAC, userAC } from '../store/reducers/app';
import notification from '../utils/openNotification';

const sendOtpApi = (params:any, callback:any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(isLoadingAC(true))
      const { data } = await $host2.post('https://api.food.zood.tj/api/auth/send-otp', params);
      if (data.payload) {
        dispatch(otpAC(data.otp));
        callback(data.payload)
      }
    } catch(e:any) {
      notification('error', e.response.data.error.message)
    } finally {
      dispatch(isLoadingAC(false))
    }
  };

  const checkUserApi = (params:any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(isLoadingAC(true))
      await $host2.post('https://api.food.zood.tj/api/auth/check-user', params);  
    } catch(e:any) {
      notification('error', e.response.data.error.message)
    } finally {
      dispatch(isLoadingAC(false))
    }
  };

  const verifyOtpApi = (params:any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(isLoadingAC(true))
      const { data } = await $host2.post('https://api.food.zood.tj/api/auth/verify-otp', params);
      if (data.payload) {
        dispatch(isAuthModalOpenAC(false));
        dispatch(isAuthAC(true))
        dispatch(userAC(data.payload))
      }
    } catch(e:any) {
      notification('error', e.response.data.error.message)
    } finally {
      dispatch(isLoadingAC(false))
    }
  };

  const signUpApi = (params:any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(isLoadingAC(true))
      const { data } = await $host2.post('https://api.food.zood.tj/api/auth/consumer/sign-up', params);
      if (data.payload) {
        dispatch(isAuthModalOpenAC(false))
        dispatch(isAuthAC(true))
        dispatch(userAC(data.payload))
        const now = new Date();
        const expiryTime = new Date(now.getTime() + 1 * 60 * 60 * 1000); // Время истечения
        document.cookie = `user=${encodeURIComponent(JSON.stringify(data.payload))}; expires=${expiryTime.toUTCString()}; max-age=3600 path=/`;
      }
    } catch(e:any) {
      notification('error', e.response.data.error.message)
    } finally {
      dispatch(isLoadingAC(false))
    }
  };


  export {
    sendOtpApi,
    checkUserApi,
    verifyOtpApi,
    signUpApi
  };