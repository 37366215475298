import React from 'react';
import successIcon from '../../assets/images/successIcon.svg';
import { useNavigate } from 'react-router-dom';

const AcceptedOrder = () => {
  const navigate = useNavigate();
  return (
    <div className='flex justify-center mb-14'>
      <div className='w-[1180px]'>
        <div className='flex justify-center'>
          <div className='min-h-[355px] mt-10'>
            <div className='flex justify-center mb-5'>
              <img className='w-[80px] h-[80px] sm:w-[110px] sm:h-[110px]' src={successIcon} alt='' />
            </div>
            <p className='text-center sm:text-lg'>Ваш заказ успешно оформлен.</p>
            <p className='text-center sm:text-lg'>Наш курьер свяжеться с вами в ближайшее время.</p>
            <div className='flex justify-center'>
              <button
                className='h-full w-[220px] bg-yellow text-black rounded-xl font-semibold py-3 mt-5'
                onClick={() => navigate('/')}
              >
                Вернуться на главную
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcceptedOrder;
