import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import RestaurantByCategory from '../../components/restaurants/RestaurantByCategory';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getRestaurantByDietTypeIdApi } from '../../http/restaurants';

const RestaurantByCategoryPage = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const restaurantByCategory = useAppSelector(state => state.restaurantsReducer.restaurantByCategory);

  useEffect(() => {
    dispatch(getRestaurantByDietTypeIdApi(location.pathname.slice(22)));
  }, []);
  return (
    <div>
      <RestaurantByCategory restaurantByCategory={restaurantByCategory} />
    </div>
  );
};

export default RestaurantByCategoryPage;
