import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import arrowLeft from '../../assets/images/arrowLeft.svg';
import confirmIcon from '../../assets/images/confirm.png';
import cookingIcon from '../../assets/images/cookingIcon.png';
import deliveringIcon from '../../assets/images/deliveringIcon.png';
import deliveredIcon from '../../assets/images/deliveredIcon.png';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { cancelOrderApi, getOrderDetailsApi } from '../../http/restaurants';
import getStatus from '../../utils/getStatus';

const OrderInfo = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const orderInfo = useAppSelector(state => state.mainReducer.orderInfo);

  const cancelOrder = (item: any) => {
    const formData: any = new URLSearchParams();

    formData.append('code', 'RU');
    formData.append('order_id', item.order_id);

    dispatch(
      cancelOrderApi(formData, () => {
        dispatch(getOrderDetailsApi(location.pathname.slice(10)));
      })
    );
  };

  useEffect(() => {
    dispatch(getOrderDetailsApi(location.pathname.slice(10)));
  }, []);

  return (
    <div className='flex justify-center mb-14'>
      <div className='w-[1180px] px-5'>
        <div className='flex items-center'>
          <Link to='/restaurant' className='flex items-center'>
            <img src={arrowLeft} className='w-[35px]' alt='' onClick={() => navigate(-1)} />
          </Link>
          <p className='font-semibold ml-4 max-sm:text-xl text-2xl text-black'>Информация о заказе</p>
        </div>
        <div className='mt-10'>
          <p className='text-lg'>
            ID: <span className='font-semibold'>{orderInfo?.order_id}</span>{' '}
          </p>
          <p className='text-base text-slate-600 mt-2'>
            Время заказа: <span className=''>{orderInfo?.created_at}</span>
          </p>
          <div className='mt-3'>{getStatus(orderInfo?.status, 16)}</div>
          <p className='mt-10 text-xl font-semibold'>{orderInfo?.restaurant_name}</p>
          <div className='mt-5 grid grid-cols-4 gap-5'>
            <div className='relative'>
              <div
                className='sm:w-[100px] sm:h-[100px] w-[60px] h-[60px] flex items-center justify-center rounded-full'
                style={{ backgroundColor: orderInfo?.status === 'arrived_at_restuarant' ? '#ffc700' : '#F5F5F5' }}
              >
                <img className='sm:w-[60px] sm:h-[60px] w-[40px] h-[40px]' src={confirmIcon} alt='' />
              </div>
              <div className='absolute top-1/2 sm:left-[105px] left-[60px] w-full h-[4px] bg-[#F5F5F5]'></div>
            </div>
            <div className='relative'>
              <div
                className='sm:w-[100px] sm:h-[100px] w-[60px] h-[60px] flex items-center justify-center rounded-full'
                style={{ backgroundColor: orderInfo?.status === 'preparing' ? '#ffc700' : '#F5F5F5' }}
              >
                <img className='sm:w-[60px] sm:h-[60px] w-[40px] h-[40px]' src={cookingIcon} alt='' />
              </div>
              <div className='absolute top-1/2 sm:left-[105px] left-[60px] w-full h-[4px] bg-[#F5F5F5]'></div>
            </div>
            <div className='relative'>
              <div
                className='sm:w-[100px] sm:h-[100px] w-[60px] h-[60px] flex items-center justify-center rounded-full'
                style={{ backgroundColor: orderInfo?.status === 'arrived_at_destination' ? '#ffc700' : '#F5F5F5' }}
              >
                <img className='sm:w-[60px] sm:h-[60px] w-[40px] h-[40px]' src={deliveringIcon} alt='' />
              </div>
              <div className='absolute top-1/2 sm:left-[105px] left-[60px] w-full h-[4px] bg-[#F5F5F5]'></div>
            </div>
            <div className='relative'>
              <div
                className='sm:w-[100px] sm:h-[100px] w-[60px] h-[60px] flex items-center justify-center rounded-full'
                style={{ backgroundColor: orderInfo?.status === 'delivered' ? '#ffc700' : '#F5F5F5' }}
              >
                <img className='sm:w-[60px] sm:h-[60px] w-[40px] h-[40px]' src={deliveredIcon} alt='' />
              </div>
            </div>
          </div>

          <div className='mt-10'>
            {orderInfo?.order_details?.map((item: any) => (
              <div className='flex items-center py-3' style={{ borderBottom: '1px solid #eee' }}>
                <span className='font-semibold mr-10'>
                  {item.qty} x {item.title}
                </span>
                <span>{item.item_price} TJS</span>
              </div>
            ))}
          </div>

          <div className='mt-10 pb-3' style={{ borderBottom: '1px solid #eee' }}>
            <p>
              <span>Общая сумма в корзине: </span>
              <span>{orderInfo?.gross_amount}</span>
            </p>
            <p>
              <span>Стоимость доставки: </span>
              <span>{orderInfo?.delivery_fee}</span>
            </p>
            <p className='text-lg font-semibold'>
              <span>Общая сумма к оплате: </span>
              <span>{orderInfo?.net_amount}</span>
            </p>
          </div>
          {orderInfo?.status !== 'cancelled' ? (
            <div
              onClick={() => cancelOrder(orderInfo)}
              className='bg-[#EFEFEF] mt-5 w-[162px] h-[48px] flex items-center rounded-xl text-center cursor-pointer'
            >
              <p className='text-center w-full'>Отменить</p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default OrderInfo;
