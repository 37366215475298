import { $host }from './index';
import { AppDispatch } from '../store';
import { categoriesAC, currentOrdersAC, pastOrdersAC, promotionsAC } from '../store/reducers/main';
import { isLoadingAC } from '../store/reducers/app';

const getCategoriesApi = () => async (dispatch: AppDispatch) => {
    try {
      dispatch(isLoadingAC(true))
      const { data } = await $host.get('https://api.food.zood.tj:2222/get-categories');
        dispatch(categoriesAC(data))
    } finally {
      dispatch(isLoadingAC(false))
    }
  };

const getPromotionsApi = () => async (dispatch: AppDispatch) => {
    try {
      dispatch(isLoadingAC(true))
      const { data } = await $host.get('https://api.food.zood.tj:2222/get-auctions-and-discount');
        dispatch(promotionsAC(data))
    } finally {
      dispatch(isLoadingAC(false))
    }
  };

  const getOrdersApi = (type:any, consumerId:any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(isLoadingAC(true))
      const { data } = await $host.get(`https://api.food.zood.tj/api/order/get-order-list?request_type=${type}&consumer_id=${consumerId}&code=RU`);
        if (type === 'past') {
          dispatch(pastOrdersAC(data.payload))
        }
        if (type === 'current') {
          dispatch(currentOrdersAC(data.payload))
        }
    } finally {
      dispatch(isLoadingAC(false))
    }
  };


  export {
    getCategoriesApi,
    getPromotionsApi,
    getOrdersApi
  };