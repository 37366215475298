import React, { useEffect, useState } from 'react';
import busketIcon from '../assets/images/busketIcon.svg';
import deliveryBusket from '../assets/images/deliveryBusket.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { addToCartApi, getCartItemsApi, removeFromCartApi, updateCartApi } from '../http/restaurants';
import { isAuthModalOpenAC } from '../store/reducers/app';
import notification from '../utils/openNotification';
import { addToKfcOrderAC, removeFromKfcOrderAC } from '../store/reducers/restaurants';

const Busket = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const orders = useAppSelector(state => state.restaurantsReducer.orders);
  const kfcMenuList = useAppSelector(state => state.restaurantsReducer.kfcMenuList);

  const user = useAppSelector(state => state.appReducer.user);

  const orderPrice = useAppSelector(state => state.restaurantsReducer.orderPrice);

  const selectedDish = (dish: any): any => orders.find((item: any) => dish.item_id === item.item_id);

  const addToCart = (item: any) => {
    const formData: any = new URLSearchParams();

    formData.append('code', 'RU');
    formData.append('qty', '1');
    formData.append('item_id', item.item_id);
    formData.append('consumer_id', user.consumer_id);
    formData.append('restaurant_id', location.pathname.slice(12));
    formData.append('store_type', '');
    formData.append('item_options', '[]');
    formData.append('item_instruction', '');

    dispatch(addToCartApi(formData, user.consumer_id));
    addKfcOrder(item);
  };

  const updateCart = (item: any) => {
    const formData: any = new URLSearchParams();
    const cart = selectedDish(item);

    if (cart.qty <= 1) {
      removeFromCart(cart.cart_id);
      removeFromKfcOrder(item);
    } else {
      formData.append('code', 'RU');
      formData.append('qty', cart.qty - 1);
      formData.append('item_id', cart.item_id);
      formData.append('consumer_id', user.consumer_id);
      formData.append('restaurant_id', location.pathname.slice(12));
      formData.append('store_type', '');
      formData.append('item_options', '[]');
      formData.append('item_instruction', '');

      dispatch(updateCartApi(formData, user.consumer_id, cart.cart_id));
      removeFromKfcOrder(item);
    }
  };

  const addKfcOrder = (item: any) => {
    for (let i = 0; i < kfcMenuList.length; i++) {
      if (item.item_name === kfcMenuList[i].name) {
        const dish = {
          cost: kfcMenuList[i].price,
          id: kfcMenuList[i].id,
          name: kfcMenuList[i].name,
          price: kfcMenuList[i].price,
          promos: [],
          quantity: 1,
        };
        dispatch(addToKfcOrderAC(dish));
      }
    }
  };

  const removeFromKfcOrder = (item: any) => {
    for (let i = 0; i < kfcMenuList.length; i++) {
      if (item.item_name === kfcMenuList[i].name) {
        dispatch(removeFromKfcOrderAC(kfcMenuList[i].id));
      }
    }
  };

  const removeFromCart = (cart_id: any) => {
    dispatch(removeFromCartApi(user.consumer_id, cart_id));
  };

  useEffect(() => {
    if (user.consumer_id) {
      dispatch(getCartItemsApi(user.consumer_id));
    }
  }, [user]);

  return (
    <div className=''>
      <div
        className='w-[380px] h-[490px] rounded-3xl p-5 top-[100px] max-lg:hidden'
        style={{ backgroundColor: '#F6F6F6' }}
      >
        <div className='flex items-center justify-between'>
          <div className='flex items-center'>
            <img src={busketIcon} alt='' />
            <p className='ml-5 font-semibold !text-base'>Корзина</p>
          </div>
          {/* <p className='text-slate-400 cursor-pointer' onClick={() => dispatch(clearOrderAC([]))}>
            Очистить
          </p> */}
        </div>
        <div className='h-[280px] mt-5 overflow-y-auto'>
          {orders.length > 0 ? (
            <div>
              {orders.map((order: any) => (
                <div className='flex justify-between items-center mb-5'>
                  <img src={order?.item_cover_photo} alt='' className='w-[80px] h-[80px] rounded-xl' />
                  <div className='w-[120px] !text-base'>
                    <p className='font-semibold'>{order?.item_name}</p>
                    <p className='mt-1'>{order?.price} TJS</p>
                  </div>
                  <div>
                    <div
                      style={{ backgroundColor: '#DCDCDC' }}
                      className='w-[100px] text-black rounded-3xl font-semibold py-1 mt-auto flex justify-between items-center text-black px-5 text-base'
                    >
                      <span
                        className='cursor-pointer'
                        onClick={() => {
                          updateCart(order);
                        }}
                      >
                        -
                      </span>
                      <span>{order?.qty}</span>
                      <span
                        className='cursor-pointer'
                        onClick={() => {
                          addToCart(order);
                        }}
                      >
                        +
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className='h-full flex items-center justify-center'>
              <div className='!text-base'>
                <p className='text-slate-500'>Здесь появятся товары</p>
                <p className='text-slate-500'>которые вы добавите</p>
              </div>
            </div>
          )}
        </div>
        <div className='mt-3'>
          {orders.length > 0 ? (
            <div>
              <div className='flex items-center'>
                <img src={deliveryBusket} alt='' />
                <p className='ml-3 !text-base'>Доставка от 10 TJS</p>
              </div>
              <button
                className='h-full w-full bg-yellow text-black rounded-3xl font-semibold py-3 mt-5 !text-base'
                onClick={() => {
                  if (user?.consumer_id) {
                    if (orders.length > 0) {
                      navigate('/order');
                    } else {
                      notification('warning', 'Корзина пуста!');
                    }
                  } else {
                    dispatch(isAuthModalOpenAC(true));
                  }
                }}
              >
                Оформить заказ {orderPrice} TJS
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Busket;
