import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import arrowLeft from '../../assets/images/arrowLeft.svg';
import deliveryIcon from '../../assets/images/deliveryIcon.svg';
import starIcon from '../../assets/images/starIcon.svg';
import kombo from '../../assets/images/kombo.svg';
import { useAppSelector } from '../../hooks/redux';

const Favorites = () => {
  const navigate = useNavigate();
  const restaurants: any = useAppSelector(state => state.restaurantsReducer.restaurants);
  const [pickedFav, setPickedFav] = useState('restaurant');

  const data = [
    {
      categoryTitle: 'Комбо Дуэт',
      title: 'Комбо Дуэт',
      price: '49',
    },
    {
      categoryTitle: 'Комбо Дуэт',
      title: 'Комбо Дуэт',
      price: '49',
    },
  ];

  return (
    <div className='flex justify-center mb-14'>
      <div className='w-[1180px] px-5'>
        <div className='flex items-center'>
          <Link to='/restaurant' className='flex items-center'>
            <img src={arrowLeft} className='w-[35px]' alt='' onClick={() => navigate(-1)} />
          </Link>
          <span className='font-semibold ml-4 max-sm:text-xl text-2xl text-black'>Избранные</span>
        </div>
        <div className='mt-10'>
          <div className='flex'>
            <div
              onClick={() => setPickedFav('restaurant')}
              className={`${pickedFav === 'restaurant' ? 'bg-yellow' : 'bg-[#EFEFEF]'} mr-5 w-[162px] h-[48px] flex items-center rounded-xl text-center cursor-pointer`}
            >
              <p className='text-center w-full'>Рестораны</p>
            </div>
            <div
              onClick={() => setPickedFav('dish')}
              className={`${pickedFav === 'dish' ? 'bg-yellow' : 'bg-[#EFEFEF]'} mr-5 w-[162px] h-[48px] flex items-center rounded-xl text-center cursor-pointer`}
            >
              <p className='text-center w-full'>Блюда</p>
            </div>
          </div>
          {pickedFav === 'restaurant' ? (
            <div className='mt-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10'>
              {restaurants.slice(0, 3).map((item: any) => (
                <div className='cursor-pointer'>
                  <div>
                    <img src={item?.restaurant?.logo} alt='' className='h-[168px] w-full rounded-xl' />
                  </div>
                  <div className='mt-3'>
                    <div className='flex justify-between'>
                      <p className='font-semibold md:text-lg text-base text-black'>
                        {item?.restaurant?.restaurantNameRU}
                      </p>
                      <div className='flex'>
                        <img src={deliveryIcon} className='w-6 h-6' alt='' />
                        <p className='ml-3'>{item?.restaurant?.preparation_time} мин</p>
                      </div>
                    </div>
                    <div className='flex justify-between text-sm mt-1'>
                      <p className='text-sm'>{item?.category_name}</p>
                      <div className='flex items-center'>
                        <span>Рейтинг</span>
                        <img className='ml-2 w-3 h-3' src={starIcon} alt='' />
                        <p className='ml-2'>{item?.restaurant?.rating_list[0]?.avg_rating}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : null}

          {pickedFav === 'dish' ? (
            <div className='mt-10'>
              <div className='grid sm:grid-cols-4 grid-cols-2 gap-x-5 gap-y-5'>
                {data?.map((item: any) => (
                  <div
                    id={item.item_id}
                    style={{ backgroundColor: '#F8F8F8', borderRadius: '30px' }}
                    className='p-1 flex flex-col'
                  >
                    <img
                      src={kombo}
                      alt=''
                      className='w-full max-sm:min-h-[165px] min-h-[230px]'
                      style={{ borderRadius: '30px' }}
                    />
                    <div className='p-3 h-full flex flex-col justify-between'>
                      <div>
                        <p className='text-lg font-semibold'>{item?.title}</p>
                        <p className='text-sm text-slate-400'>{item?.ingredients}</p>
                      </div>
                      <div>
                        <p className='my-3 text-2xl font-semibold'>{item.price} TJS</p>
                        <button className='h-[40px] w-full bg-yellow text-black rounded-3xl font-semibold py-2 mt-auto'>
                          Заказать
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Favorites;
