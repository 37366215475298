import { Modal } from 'antd';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { authStateAC, isAuthModalOpenAC } from '../../store/reducers/app';
import Auth from './Auth';
import Otp from './Otp';
import Registration from './Registration';

const AuthModal = () => {
  const dispatch = useAppDispatch();
  const isAuthModalOpen = useAppSelector(state => state.appReducer.isAuthModalOpen);
  const authState = useAppSelector(state => state.appReducer.authState);

  return (
    <Modal
      width={400}
      footer={null}
      open={isAuthModalOpen}
      onOk={() => {
        dispatch(isAuthModalOpenAC(false));
        dispatch(authStateAC('auth'));
      }}
      onCancel={() => {
        dispatch(isAuthModalOpenAC(false));
        dispatch(authStateAC('auth'));
      }}
    >
      {authState === 'auth' ? <Auth /> : null}
      {authState === 'otp' ? <Otp /> : null}
      {authState === 'reg' ? <Registration /> : null}
    </Modal>
  );
};

export default AuthModal;
