import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ruRU from 'antd/lib/locale/ru_RU';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import { setupStore } from './store';
import './index.scss';
import './assets/fonts/stylesheet.css';

const store = setupStore();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <ConfigProvider
    locale={ruRU}
    theme={{
      token: {
        colorPrimary: '#ffb700',
      },
    }}
  >
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </ConfigProvider>
);

reportWebVitals();
