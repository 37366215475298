import arrowLeft from '../../assets/images/arrowLeft.svg';
import cursorIcon from '../../assets/images/cursorIcon.svg';
import clockIcon from '../../assets/images/clockIcon.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import MapComponent from '../../components/Map';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { mapModalAC } from '../../store/reducers/main';
import {
  addToCartApi,
  deliveryChargeApi,
  orderFromKfcApi,
  postOrderApi,
  removeFromCartApi,
  updateCartApi,
} from '../../http/restaurants';
import notification from '../../utils/openNotification';
import { isAuthModalOpenAC } from '../../store/reducers/app';
import { useEffect, useState } from 'react';
import { addToKfcOrderAC, clearOrderAC, kfcOrderAC, removeFromKfcOrderAC } from '../../store/reducers/restaurants';
import kfcList from '../../utils/kfcRestaurantList';

export const Order = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const isMapModalOpen = useAppSelector(state => state.mainReducer.isMapModalOpen);
  const fare = useAppSelector(state => state.mainReducer.fare);
  const orders = useAppSelector(state => state.restaurantsReducer.orders);
  const orderPrice = useAppSelector(state => state.restaurantsReducer.orderPrice);
  const coordinates = useAppSelector(state => state.restaurantsReducer.coordinates);
  const restaurantinfo = useAppSelector(state => state.restaurantsReducer.restaurantinfo);
  const addressName = useAppSelector(state => state.mainReducer.addressName);
  const kfcOrder = useAppSelector(state => state.restaurantsReducer.kfcOrder);

  const user = useAppSelector(state => state.appReducer.user);

  const restaurantId = useAppSelector(state => state.restaurantsReducer.restaurantId);
  const pickedKfcRestaurantId = useAppSelector(state => state.restaurantsReducer.pickedKfcRestaurantId);
  const kfcMenuList = useAppSelector(state => state.restaurantsReducer.kfcMenuList);

  const [specialInstruction, setSpecialInstruction] = useState('');

  const handleOk = () => {
    dispatch(mapModalAC(false));
  };

  const handleCancel = () => {
    dispatch(mapModalAC(false));
  };

  const selectedDish = (dish: any): any => orders.find((item: any) => dish.item_id === item.item_id);

  const addToCart = (item: any) => {
    const formData: any = new URLSearchParams();

    formData.append('code', 'RU');
    formData.append('qty', '1');
    formData.append('item_id', item.item_id);
    formData.append('consumer_id', user.consumer_id);
    formData.append('restaurant_id', item.restaurant_id);
    formData.append('store_type', '');
    formData.append('item_options', '[]');
    formData.append('item_instruction', '');

    dispatch(addToCartApi(formData, user.consumer_id));
    addKfcOrder(item);
  };

  const updateCart = (item: any) => {
    const formData: any = new URLSearchParams();
    const cart = selectedDish(item);

    if (cart.qty <= 1) {
      removeFromCart(cart.cart_id);
      removeFromKfcOrder(item);
    } else {
      formData.append('code', 'RU');
      formData.append('qty', cart.qty - 1);
      formData.append('item_id', cart.item_id);
      formData.append('consumer_id', user.consumer_id);
      formData.append('restaurant_id', item.restaurant_id);
      formData.append('store_type', '');
      formData.append('item_options', '[]');
      formData.append('item_instruction', '');

      dispatch(updateCartApi(formData, user.consumer_id, cart.cart_id));
      removeFromKfcOrder(item);
    }
  };

  const addKfcOrder = (item: any) => {
    for (let i = 0; i < kfcMenuList.length; i++) {
      if (item.item_name === kfcMenuList[i].name) {
        const dish = {
          cost: kfcMenuList[i].price,
          id: kfcMenuList[i].id,
          name: kfcMenuList[i].name,
          price: kfcMenuList[i].price,
          promos: [],
          quantity: 1,
        };
        dispatch(addToKfcOrderAC(dish));
      }
    }
  };

  const removeFromKfcOrder = (item: any) => {
    for (let i = 0; i < kfcMenuList.length; i++) {
      if (item.item_name === kfcMenuList[i].name) {
        dispatch(removeFromKfcOrderAC(kfcMenuList[i].id));
      }
    }
  };

  const removeFromCart = (cart_id: any) => {
    dispatch(removeFromCartApi(user.consumer_id, cart_id));
  };

  const checkIsKfc = (restaurantId: any) => kfcList.find((item: any) => item.zoodId === restaurantId);

  const postOrder = () => {
    if (orders.length > 0) {
      if (coordinates.length > 0) {
        if (user.consumer_id) {
          const query = {
            code: 'RU',
            place_order_json: {
              tax_details: [],
              coupon_value: 0,
              special_instruction: specialInstruction,
              code: 'RU',
              discount_type: 'percentage',
              transaction_id: '',
              food_allergies_note: '',
              deliver_to_longitude: coordinates[1],
              discount_value: '0',
              delivery_fee: fare,
              city: '',
              credit_deduct_amount: '0',
              coupon_id: '',
              order_id: '',
              address_type: 'home',
              restaurant_id: restaurantId,
              deliver_to: '',
              apt_name: '',
              payment_with: 'cash',
              net_amount: orderPrice + fare,
              gross_amount: orderPrice,
              restaurant_discount: 0,
              consumer_id: user.consumer_id,
              address_title: '',
              deliver_to_latitude: coordinates[0],
              coupon_type: '',
              time_slot_id: 'TSL15523441',
              coupon_discount: 0,
              landmark: 'евар',
              total_tax_amount: 0,
              floor: '1',
            },
          };

          if (checkIsKfc(restaurantId)) {
            console.log('kfc');

            dispatch(
              postOrderApi(
                { code: query.code, place_order_json: JSON.stringify(query.place_order_json) },
                (res: any) => {
                  orderFromKfc(res);
                }
              )
            );
          } else {
            console.log('not kfc');

            dispatch(
              postOrderApi(
                { code: query.code, place_order_json: JSON.stringify(query.place_order_json) },
                (res: any) => {
                  navigate('/acceptedOrder');
                  dispatch(clearOrderAC([]));
                }
              )
            );
          }
        } else {
          dispatch(isAuthModalOpenAC(true));
        }
      } else {
        notification('warning', 'Укажите адрес доставки!');
      }
    } else {
      notification('warning', 'Корзина пуста!');
    }
  };

  const orderFromKfc = (res: any) => {
    const query = {
      comment: 'Order from Zood Food',
      deliveryInfo: {
        clientName: 'Zood Food',
        phoneNumber: '+9920000000',
      },
      discriminator: coordinates[0] + ', ' + coordinates[1],
      eatsId: res?.order_id?.slice(-5),
      items: kfcOrder,
      paymentInfo: {
        itemsCost: orderPrice,
        paymentType: 'CARD',
      },
      persons: 1,
      restaurantId: pickedKfcRestaurantId,
    };

    dispatch(
      orderFromKfcApi(query, res?.order_id, () => {
        navigate('/acceptedOrder');
        dispatch(clearOrderAC([]));
        dispatch(kfcOrderAC([]));
      })
    );
  };

  useEffect(() => {
    if (coordinates.length > 0) {
      const formData: any = new URLSearchParams();

      formData.append('deliver_to_latitude', coordinates[0]);
      formData.append('deliver_to_longitude', coordinates[1]);
      formData.append('restaurant_id', restaurantId);
      formData.append('store_type', '');

      dispatch(deliveryChargeApi(formData));
    }
  }, []);

  return (
    <div className='flex justify-center mb-14'>
      <div className='w-[1180px] px-5'>
        <Link to='/restaurant' className='mb-14 flex items-center'>
          <img src={arrowLeft} className='w-[35px]' alt='' onClick={() => navigate(-1)} />
          <span className='font-semibold ml-4 max-sm:text-xl text-2xl text-black' style={{ color: '#ffc700' }}>
            Назад
          </span>
        </Link>
        <div className='flex items-center'>
          <img src={restaurantinfo.logo} alt='' className='w-full h-44 rounded-xl' />
        </div>
        <div className='mt-14 md:grid grid-cols-3 gap-5'>
          <div className='col-span-2 rounded-2xl sm:p-10 p-5 max-md:mb-7' style={{ backgroundColor: '#F6F6F6' }}>
            <div className='flex justify-between text-2xl font-semibold'>
              <p>Адрес доставки</p>
              <p className='max-sm:hidden'>Время доставки</p>
            </div>
            <div
              className='sm:flex justify-between items-center mt-5 pb-10'
              style={{ borderBottom: '2px solid #DCDCDC' }}
            >
              {coordinates.length < 1 ? (
                <div
                  className='bg-yellow max-sm:w-full w-[323px] h-[48px] flex items-center rounded-xl text-center cursor-pointer'
                  onClick={() => dispatch(mapModalAC(true))}
                >
                  <img src={cursorIcon} alt='' className='ml-4' />
                  <p className='text-center w-full font-semibold'>Укажите адрес доставки</p>
                </div>
              ) : (
                <div>
                  <div className='flex items-center'>
                    <p className='text-base font-semibold w-2/3'>{addressName}</p>
                  </div>
                  <div
                    className='bg-yellow mt-3 sm:w-[243px] h-[48px] flex items-center rounded-xl text-center cursor-pointer'
                    onClick={() => dispatch(mapModalAC(true))}
                  >
                    <img src={cursorIcon} alt='' className='ml-4' />
                    <p className='text-center w-full font-semibold'>Выбрать другой адрес</p>
                  </div>
                </div>
              )}
              <div className='flex items-center max-sm:mt-5'>
                <div>
                  <img src={clockIcon} alt='' className='ml-4 mr-4' />
                </div>
                <p className=''>Доставка 30-40 мин</p>
              </div>
            </div>
            <div className='mt-10 lg:grid grid-cols-2 gap-5'>
              <div>
                <p className='text-lg font-semibold'>Ваш заказ</p>
                <div className='lg:h-[360px] mt-5 overflow-y-auto'>
                  {orders.map((order: any) => (
                    <div className='flex justify-between items-center mb-5'>
                      <img src={order?.item_cover_photo} alt='' className='w-[80px] h-[80px] rounded-xl' />
                      <div className='w-[120px]'>
                        <p className='font-semibold'>{order?.item_name}</p>
                        <p className='mt-1'>{order?.price} TJS</p>
                      </div>
                      <div>
                        <div
                          style={{ backgroundColor: '#DCDCDC' }}
                          className='w-[100px] text-black rounded-3xl font-semibold py-1 mt-auto flex justify-between items-center text-black px-5 text-base'
                        >
                          <span
                            className='cursor-pointer'
                            onClick={() => {
                              updateCart(order);
                            }}
                          >
                            -
                          </span>
                          <span>{order?.qty}</span>
                          <span
                            className='cursor-pointer'
                            onClick={() => {
                              addToCart(order);
                            }}
                          >
                            +
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className='bg-white rounded-2xl p-5 mt-12 max-sm:hidden'>
                <textarea
                  placeholder='Комментарий к заказу'
                  className='textfield-custom h-[360px] w-full'
                  onChange={e => setSpecialInstruction(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className='col-span-1 rounded-2xl p-7' style={{ backgroundColor: '#F6F6F6' }}>
            <div className='flex flex-col'>
              <p className='text-xl font-semibold'>Способ оплаты</p>
              <div className='mt-7 pb-7' style={{ borderBottom: '2px solid #DCDCDC' }}>
                <div className='flex items-center'>
                  <p className='w-5 h-5 rounded-full mr-3' style={{ backgroundColor: '#DCDCDC' }}></p>
                  <span>Наличными</span>
                </div>
                {/* <div className='mt-5 flex items-center'>
                  <span className='w-5 h-5 rounded-full mr-3' style={{ backgroundColor: '#DCDCDC' }}></span>
                  <span>Оплата по карте</span>
                </div>
                <div className='mt-5 flex items-center'>
                  <span className='w-5 h-5 rounded-full mr-3' style={{ backgroundColor: '#DCDCDC' }}></span>
                  <span>Оплата по QR</span>
                </div> */}
              </div>
              <div className='mt-5'>
                <p className='text-lg font-semibold'>Итого</p>
                <div className='mt-7 pb-7'>
                  <div className='flex justify-between'>
                    <p>Стоимость заказа</p>
                    <span>{orderPrice} TJS</span>
                  </div>
                  <div className='flex justify-between mt-3'>
                    <p>Доставка</p>
                    <span>{fare} TJS</span>
                  </div>
                  <div className='flex justify-between mt-3'>
                    <p>Скидка</p>
                    <span>0 TJS</span>
                  </div>
                </div>
              </div>
              <button
                className='h-full w-full bg-yellow text-black rounded-3xl font-semibold py-3 mt-5'
                onClick={postOrder}
              >
                Оформить заказ {orderPrice + fare} TJS
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal width={1000} footer={false} open={isMapModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p className='mb-5 text-lg font-semibold'>Укажите адрес доставки:</p>
        <MapComponent />
      </Modal>
    </div>
  );
};
