import React, { useEffect } from 'react';
import RestaurantInfo from './RestaurantInfo';
import Busket from '../../components/Busket';
import Dishes from '../../components/Dishes';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getKfcMenuApi, getRestaurantMenuApi, kfcAuthApi } from '../../http/restaurants';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  pickedKfcRestaurantIdAC,
  restaurantIdAC,
  restaurantinfoAC,
  restaurantMenuAC,
} from '../../store/reducers/restaurants';
import { Affix } from 'antd';
import kfcList from '../../utils/kfcRestaurantList';
import openNotification from '../../utils/openNotification';

const Restaurant = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const restaurantMenu = useAppSelector(state => state.restaurantsReducer.restaurantMenu);
  const [top, setTop] = React.useState<number>(100);

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(
      getRestaurantMenuApi(location.pathname.slice(12), () => {
        navigate('/');
        openNotification('warning', 'Ресторан временно недоступен');
      })
    );
    dispatch(restaurantIdAC(location.pathname.slice(12)));

    for (let i = 0; i < kfcList.length; i++) {
      if (kfcList[i].zoodId === location.pathname.slice(12)) {
        dispatch(pickedKfcRestaurantIdAC(kfcList[i].kfcId));
        dispatch(getKfcMenuApi());
        dispatch(kfcAuthApi());
      }
    }

    return () => {
      dispatch(restaurantMenuAC([]));
      dispatch(restaurantinfoAC({}));
    };
  }, []);

  return (
    <div className='flex justify-center mb-14'>
      <div className='w-[1180px] mx-5'>
        <div className='grid grid-cols-3 gap-10'>
          <div className='lg:col-span-2 col-span-3'>
            <RestaurantInfo />
            <div className='mt-10'>
              <Dishes data={restaurantMenu} />
            </div>
          </div>
          <div className='lg:col-span-1'>
            <Affix offsetTop={top}>
              <Busket />
            </Affix>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Restaurant;
