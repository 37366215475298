import { Button, Form } from 'antd';
import { useState } from 'react';
import { useAppDispatch } from '../../hooks/redux';
import { sendOtpApi } from '../../http/auth';
import { authStateAC, mobileNumberAC, otpAC, userIdAC } from '../../store/reducers/app';

const Auth = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [query, setQuery] = useState({
    type: 'consumer',
    request_type: '',
    mobile_country_code: '+992',
    mobile: '',
  });

  const sendOtp = () => {
    dispatch(
      sendOtpApi({ ...query, request_type: 'signIn' }, (data: any) => {
        dispatch(authStateAC('otp'));
        dispatch(otpAC(data.otp));
        dispatch(mobileNumberAC(query.mobile));
        dispatch(userIdAC(data.user_id));
      })
    );
  };

  return (
    <div className='mt-5'>
      <h1 className='text-2xl text-center font-semibold mb-5'>Вход</h1>
      <Form name='login' onFinish={sendOtp}>
        <Form.Item
          className='m-0'
          name='account_name'
          rules={[
            { required: true, message: 'Обязательное поле!' },
            { min: 9, message: 'Минимальное количество символов: 9' },
          ]}
        >
          <div className='w-full h-[40px] flex items-center rounded-xl' style={{ backgroundColor: '#F5F5F5' }}>
            <input
              maxLength={9}
              onChange={e => {
                setQuery({ ...query, mobile: e.target.value });
              }}
              type='text'
              placeholder='Введите номер телефона'
              className='mx-5 h-full w-full px-5 outline-none text-base'
              style={{ backgroundColor: '#F5F5F5' }}
            />
          </div>
        </Form.Item>
        <div className='flex justify-between items-center'>
          <p
            className='text-base mr-3 text-slate-600 font-semibold cursor-pointer'
            onClick={() => dispatch(authStateAC('reg'))}
          >
            Зарегистрироваться
          </p>
          <Form.Item>
            <div className='flex justify-end mt-5'>
              <Button
                className='bg-yellow h-[40px] text-black font-semibold text-base'
                type='primary'
                htmlType='submit'
              >
                Войти
              </Button>
            </div>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default Auth;
