import React from 'react';
import banner from '../../assets/images/banner.svg';

const Banner = () => {
  return (
    <div className='flex justify-center mt-20 max-md:hidden'>
      <div className=''>
        <img src={banner} alt='' />
      </div>
    </div>
  );
};

export default Banner;
