import { createSlice, PayloadAction } from '@reduxjs/toolkit';


interface AppState {
  isLoading: number,
  isAuthModalOpen: boolean,
  user: any,
  authState: string,
  otp: any,
  mobileNumber:any,
  userId: any,
  isAuth: boolean,
  activeRequestsCount: number
}

const initialState: AppState = {
  isLoading: 0,
  isAuthModalOpen: false,
  user: {},
  authState: 'auth',
  otp: '',
  mobileNumber: '',
  userId: '',
  isAuth: false,
  activeRequestsCount: 0
};

const app = createSlice({
  name: 'app',
  initialState,
  reducers: {
    isAuthAC(state, action: PayloadAction<any>) {     
      state.isAuth = action.payload;
    },
    userIdAC(state, action: PayloadAction<any>) {     
      state.userId = action.payload;
    },
    mobileNumberAC(state, action: PayloadAction<any>) {     
      state.mobileNumber = action.payload;
    },
    otpAC(state, action: PayloadAction<any>) {     
      state.otp = action.payload;
    },
    authStateAC(state, action: PayloadAction<any>) {     
      state.authState = action.payload;
    },
    userAC(state, action: PayloadAction<any>) {     
      state.user = action.payload;
    },
    isAuthModalOpenAC(state, action: PayloadAction<any>) {     
      state.isAuthModalOpen = action.payload;
    },
    isLoadingAC(state, action: PayloadAction<boolean>) {
      if (action.payload) {
        state.isLoading += 1;
      } else {
        state.isLoading -= 1;
      }
    },
  },
});

export const { 
  isLoadingAC, 
  isAuthModalOpenAC, 
  userAC, 
  authStateAC, 
  otpAC, 
  mobileNumberAC, 
  userIdAC,
  isAuthAC 
} = app.actions;
export default app.reducer;
