import { Tag } from 'antd';

const getStatus = (status: string, size: number = 12) => {
  switch (status) {
    case 'cancelled':
      return (
        <Tag style={{ fontSize: size }} color='error' className='m-0'>
          Отменен
        </Tag>
      );
    case 'new':
      return (
        <Tag style={{ fontSize: size }} color='processing' className='m-0'>
          Обрабатывается
        </Tag>
      );
    case 'arrived_at_restuarant':
      return (
        <Tag style={{ fontSize: size }} color='processing' className='m-0'>
          Принят
        </Tag>
      );
    case 'delivered':
      return (
        <Tag style={{ fontSize: size }} color='processing' className='m-0'>
          Доставлено
        </Tag>
      );
    case 'preparing':
      return (
        <Tag style={{ fontSize: size }} color='processing' className='m-0'>
          Готовится
        </Tag>
      );
    case 'ready':
      return (
        <Tag style={{ fontSize: size }} color='processing' className='m-0'>
          Еда готова
        </Tag>
      );
    case 'arrived_at_destination':
      return (
        <Tag style={{ fontSize: size }} color='processing' className='m-0'>
          Курьер едет
        </Tag>
      );
    default:
      break;
  }
};

export default getStatus;
