import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import arrowLeft from '../../assets/images/arrowLeft.svg';
import { useAppSelector } from '../../hooks/redux';

const Profile = () => {
  const navigate = useNavigate();
  const user = useAppSelector(state => state.appReducer.user);

  return (
    <div className='flex justify-center mb-14'>
      <div className='w-[1180px] px-5'>
        <div className='flex items-center'>
          <Link to='/restaurant' className='flex items-center'>
            <img src={arrowLeft} className='w-[35px]' alt='' onClick={() => navigate(-1)} />
          </Link>
          <span className='font-semibold ml-4 max-sm:text-xl text-2xl text-black'>Настройки профиля</span>
        </div>
        <div className='mt-10 ml-12'>
          <div>
            <p className='mb-2'>Имя:</p>
            <div className='h-[60px]'>
              <input
                value={user.name}
                type='text'
                placeholder='Введите имя'
                className='h-full w-[540px] px-5 outline-none rounded-xl'
                style={{ backgroundColor: '#F5F5F5' }}
              />
            </div>
          </div>
          <div className='mt-5'>
            <p className='mb-2'>Номер телефона:</p>
            <div className='h-[60px]'>
              <input
                value={user.mobile}
                type='text'
                placeholder='Введите номер телефона'
                className='h-full w-[540px] px-5 outline-none rounded-xl'
                style={{ backgroundColor: '#F5F5F5' }}
              />
            </div>
          </div>
          <div
            className='mt-10 max-sm:hidden bg-yellow w-[112px] h-[48px] flex items-center rounded-xl text-center cursor-pointer'
            style={{ backgroundColor: '#EFEFEF' }}
          >
            <p className='text-center w-full'>Выйти</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
