import { createSlice, PayloadAction } from '@reduxjs/toolkit';


interface MainState {
  categories: any;
  promotions: any;
  isMapModalOpen: any
  fare: any
  addressName: any,
  currentOrders: any,
  pastOrders:any
  orderInfo: any
}

const initialState: MainState = {
    categories: [],
    promotions: [],
    isMapModalOpen: false,
    fare: 0,
    addressName: '',
    currentOrders: [],
    pastOrders: [],
    orderInfo: ''
};

const main = createSlice({
  name: 'main',
  initialState,
  reducers: {
    promotionsAC(state, action: PayloadAction<any>) {     
      state.promotions = action.payload;
    },
    categoriesAC(state, action: PayloadAction<any>) {     
      state.categories = action.payload;
    },
    mapModalAC(state, action: PayloadAction<any>) {     
      state.isMapModalOpen = action.payload;
    },
    fareAC(state, action: PayloadAction<any>) {     
      state.fare = action.payload;
    },
    addressNameAC(state, action: PayloadAction<any>) {     
      state.addressName = action.payload;
    },
    currentOrdersAC(state, action: PayloadAction<any>) {     
      state.currentOrders = action.payload;
    },
    pastOrdersAC(state, action: PayloadAction<any>) {     
      state.pastOrders = action.payload;
    },
    orderInfoAC(state, action: PayloadAction<any>) {     
      state.orderInfo = action.payload;
    },
  },
});

export const { categoriesAC, promotionsAC, mapModalAC, fareAC, addressNameAC, currentOrdersAC, pastOrdersAC, orderInfoAC } = main.actions;
export default main.reducer;
